import { IUseKeycloak } from "react-keycloak-manager";
import {
	IAlarm,
	ICalendar,
	IDayToCheck,
	IDaysToCheck,
	IGate,
	IZone,
} from "../interface/Entities";

export async function getCalendars({
	createURL,
	fetchList,
}: IUseKeycloak): Promise<ICalendar[] | null> {
	return await fetchList<ICalendar>(createURL("Calendar/GetCalendars"));
}

export async function saveCalendar(
	{ createURL, post }: IUseKeycloak,
	calendar: ICalendar,
) {
	return await post<ICalendar>(createURL("Calendar/SetCalendar"), calendar);
}

export async function deleteCalendar(
	{ createURL, deleteItem }: IUseKeycloak,
	calendar: ICalendar,
) {
	return await deleteItem<ICalendar>(
		createURL("Calendar/DeleteCalendar"),
		calendar,
	);
}

export async function loadCalendar(
	{ createURL, fetchJson }: IUseKeycloak,
	id: string,
): Promise<ICalendar | null> {
	var url = createURL("Calendar/GetCalendar");
	url.searchParams.set("id", id);

	return await fetchJson<ICalendar>(url);
}

export async function checkPeriod(
	{ createURL, postList }: IUseKeycloak,
	listdaysToCheck: IDaysToCheck,
): Promise<IDayToCheck[] | null> {
	return await postList<IDaysToCheck, IDayToCheck>(
		createURL("Calendar/DayIsEnable"),
		listdaysToCheck,
	);
}

export async function getAlarmsState({
	createURL,
	paginatePost,
}: IUseKeycloak): Promise<IAlarm[]> {
	return await paginatePost<IAlarm>(createURL("Alarms/GetAlarmsState"));
}

export async function refreshAlarms({
	createURL,
	post,
}: IUseKeycloak): Promise<string> {
	return await post(createURL("Alarms/Refresh"));
}

export async function getGatesEnabled({
	createURL,
	fetchList,
}: IUseKeycloak): Promise<IGate[] | null> {
	var url = createURL("Gates/GetEnabled");
	url.searchParams.set("allDoors", String(false));
	return await fetchList<IGate>(url.toJSON());
}

export async function getTestGatesEnabled(
	{ createURL, fetchList }: IUseKeycloak,
	now: Date,
): Promise<IGate[] | null> {
	var url = createURL("Gates/GetEnabledTest");
	url.searchParams.set("allDoors", String(true));
	url.searchParams.set("now", now.toISOString());
	return await fetchList<IGate>(url.toJSON());
}

export async function openDoor(
	{ createURL, post }: IUseKeycloak,
	door: IZone,
): Promise<string> {
	return await post<IZone>(createURL("Gates/Open"), door);
}

export async function refreshGates({
	createURL,
	post,
}: IUseKeycloak): Promise<string> {
	return await post(createURL("Gates/Refresh"));
}

export async function getGates({
	createURL,
	fetchList,
}: IUseKeycloak): Promise<IGate[] | null> {
	return await fetchList<IGate>(createURL("Gates/GetGates"));
}

export async function getServerTime({
	createURL,
	fetchJson,
}: IUseKeycloak): Promise<Date | null> {
	return await fetchJson<Date>(createURL("Debug/GetServerTime"));
}

export async function getUtcServerTime({
	createURL,
	fetchJson,
}: IUseKeycloak): Promise<Date | null> {
	return await fetchJson<Date>(createURL("Debug/getUtcServerTime"));
}
