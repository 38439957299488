export function ProtectedElement({
	enabled,
	children,
}: {
	enabled: () => boolean | undefined;
	children: JSX.Element | null;
}): JSX.Element | null {
	if (enabled()) return children;
	else return null;
}
