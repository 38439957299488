import { Fragment, useCallback, useEffect, useState } from "react";
import {
	AttributeType,
	IKeyCloakGroup,
	useKeycloak,
} from "react-keycloak-manager";
import GroupsTable from "../components/GroupsTable";
import { Box, Button, Modal, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import NewGroup from "../components/NewGroup";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog, SnackAlert } from "react-vevy-library";
import { DoorGroup, VppGroupType } from "../interface/Constants";

export default function Groups() {
	const [groups, setGroups] = useState<IKeyCloakGroup[]>([]);
	const [refresh, setRefresh] = useState(0);
	const [group, setGroup] = useState<IKeyCloakGroup>();
	const [showNewGroup, setShowNewGroup] = useState<boolean>(false);
	const [showDeleteGroup, setShowDeleteGroup] = useState(false);
	const [showAlert, setShowAlert] = useState<boolean>(false);
	const navigator = useNavigate();

	const { findGroupsByAttribute, findGroupsByName, createGroup, deleteGroup } =
		useKeycloak();
	const { t } = useTranslation();

	const fetchGroups = useCallback(async () => {
		var groupList = await findGroupsByAttribute(VppGroupType, DoorGroup);
		setGroups(groupList ?? []);
	}, [refresh]);

	useEffect(() => {
		fetchGroups();
	}, [fetchGroups, refresh]);

	function onCloseDialog(): void {
		setShowDeleteGroup(false);
	}

	async function onConfirmNewGroup(name: string): Promise<void> {
		if (await groupExists(name)) {
			setShowAlert(true);
			return;
		}
		var attributes: AttributeType = {};
		attributes[VppGroupType] = [DoorGroup];
		await createGroup(name, attributes);
		var groups = await findGroupsByName(name, false);
		setShowNewGroup(false);
		navigator(`/group?id=${groups[0].id}`);
		setRefresh(Date.now());
	}

	async function onConfirmDeleteGroup(): Promise<void> {
		await deleteGroup(group?.id!);
		onCloseDialog();
		setRefresh(Date.now());
	}

	async function groupExists(name: string) {
		var groupsFound = await findGroupsByName(name);
		return groupsFound.length > 0;
	}

	return (
		<>
			<GroupsTable
				groups={groups}
				onRefreshTable={() => {
					setRefresh(Date.now());
				}}
				onDeleteGroup={(c) => {
					setGroup(c);
					setShowDeleteGroup(true);
				}}
			/>
			<Box display='flex' justifyContent='flex-end'>
				<Tooltip title={t("newGroupText")}>
					<Button
						variant='contained'
						onClick={() => {
							setShowNewGroup(true);
						}}
					>
						{t("newGroup")}{" "}
					</Button>
				</Tooltip>
			</Box>
			<Modal
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
				onClose={(e, r) => {
					console.log(r);
					// if (r === "backdropClick") return;
					setShowNewGroup(false);
				}}
				open={showNewGroup}
			>
				<Fragment>
					<NewGroup
						onConfirm={onConfirmNewGroup}
						onCancel={() => setShowNewGroup(false)}
					/>
				</Fragment>
			</Modal>
			<SnackAlert
				severity='warning'
				onClose={() => {
					setShowAlert(false);
				}}
				message='Group exists'
				open={showAlert}
			/>
			<ConfirmDialog
				open={showDeleteGroup}
				title={t("deleteGroupTitle")}
				content={`${t("deleteGroupMessage")} ${group?.name}`}
				actionOk={onConfirmDeleteGroup}
				actionCancel={onCloseDialog}
			/>
		</>
	);
}
