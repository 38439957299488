import {
	Avatar,
	Box,
	Card,
	CardContent,
	CardHeader,
	FormControl,
	Grid,
	IconButton,
	MenuItem,
	Select,
	SelectChangeEvent,
	Tooltip,
	Typography,
	styled,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	AttributeType,
	IKeyCloakGroup,
	IKeyCloakUser,
	useKeycloak,
} from "react-keycloak-manager";
import ListCheckbox from "../components/ListCheckBox";
import { ICalendar } from "../interface/Entities";
import { showType, userLevel } from "../interface/Enums";
import { getCalendars } from "../libraries/RestManager";
import {
	VppAlarmsShow,
	VppCalendar,
	VppGatesShow,
	VppGroupType,
	VppUserLevel,
} from "../interface/Constants";

interface IUserMasterProps {
	user: IKeyCloakUser;
	group: IKeyCloakGroup;
	userGroups: IKeyCloakGroup[];
	onChange: (checked?: IKeyCloakGroup[]) => void;
}

export default function UserMaster({
	group,
	user,
	userGroups,
	onChange,
}: IUserMasterProps) {
	const { t } = useTranslation();
	const [level, setLevel] = useState<string>("");
	const [showGates, setShowGates] = useState<string>("");
	const [showAlarms, setShowAlarms] = useState<string>("");
	const [calendar, setCalendar] = useState<string>("");
	const [calendars, setCalendars] = useState<ICalendar[]>([]);
	const [listGroups, setListGroups] = useState<IKeyCloakGroup[]>([]);
	const keyCloak = useKeycloak();

	const selectWidth: number = 140;

	useEffect(() => {
		getCalendars(keyCloak).then((c) => {
			if (c) {
				setCalendars(c);
				c.push({ id: 0, description: t("always") });
			}
		});
	}, []);

	const fetchGroups = useCallback(async () => {
		var allGroups = await keyCloak.findGroupsByAttribute(
			VppGroupType,
			"DoorGroup",
			false,
		);
		setListGroups(allGroups);
	}, [user]);

	useEffect(() => {
		if (user) fetchGroups();
	}, [fetchGroups, user]);

	useEffect(() => {
		if (group?.attributes) {
			if (group?.attributes && group?.attributes[VppUserLevel])
				setLevel(group?.attributes[VppUserLevel]);
			else setLevel(userLevel[0]);
			if (group?.attributes && group?.attributes[VppGatesShow])
				setShowGates(group?.attributes[VppGatesShow]);
			else setShowGates(showType[0]);
			if (group?.attributes && group?.attributes[VppAlarmsShow])
				setShowAlarms(group?.attributes[VppAlarmsShow]);
			else setShowAlarms(showType[0]);
			if (group?.attributes && group?.attributes[VppCalendar])
				setCalendar(group?.attributes[VppCalendar]);
			else setCalendar("");
		}
	}, [group]);

	const CustomCard = styled(Card)({
		maxWidth: 500,
		minWidth: 300,
		minHeight: 100,
		margin: "1em",
	});

	const onSelectLevelChange = (event: SelectChangeEvent) => {
		setLevel(event.target.value);
		if (!group.attributes) group.attributes = {} as AttributeType;
		group.attributes[VppUserLevel] = [event.target.value];
		onChange();
	};
	const onCalendarChange = (event: SelectChangeEvent) => {
		setCalendar(event.target.value);
		if (!group.attributes) group.attributes = {} as AttributeType;
		group.attributes[VppCalendar] = [event.target.value];
		onChange();
	};

	const onShowGateChange = (event: SelectChangeEvent) => {
		setShowGates(event.target.value);
		if (!group.attributes) group.attributes = {} as AttributeType;
		group.attributes[VppGatesShow] = [event.target.value];
		onChange();
	};

	const onShowAlarmChange = (event: SelectChangeEvent) => {
		setShowAlarms(event.target.value);
		if (!group.attributes) group.attributes = {} as AttributeType;
		group.attributes[VppAlarmsShow] = [event.target.value];
		onChange();
	};

	function getPicture(user: IKeyCloakUser): string | undefined {
		return user?.attributes &&
			user?.attributes["picture"] &&
			user?.attributes["picture"].length > 0
			? user?.attributes.picture[0]
			: null;
	}

	return (
		<Box sx={{ maxHeight: "75vh", overflow: "auto" }}>
			<Grid
				container
				sx={{
					flex: 1,
					flexDirection: "row",
				}}
			>
				<RegistryCard />
				<SettingsCard />
				<GroupCard />
			</Grid>
		</Box>
	);

	function GroupCard() {
		return (
			<CustomCard>
				<CardHeader
					disableTypography
					title={<Typography variant='body1'>{t("groups")}</Typography>}
				></CardHeader>
				<CardContent>
					<ListCheckbox
						listGroup={listGroups}
						checked={userGroups}
						setChecked={(g) => {
							onChange(g);
						}}
					/>
				</CardContent>
			</CustomCard>
		);
	}

	function SettingsCard() {
		return (
			<CustomCard>
				<CardHeader
					disableTypography
					title={<Typography variant='body1'>{t("settings")}</Typography>}
				/>
				<CardContent
					sx={{
						display: "flex",
						flexDirection: "column",
						rowGap: 1,
					}}
				>
					<RowLevel />
					<RowCalendar />
					<RowDoors />
					<RowAlarms />
				</CardContent>
			</CustomCard>
		);
	}

	function RowLevel() {
		return (
			<Grid container sx={{ flexGrow: 1, alignItems: "center" }}>
				<Grid item xs={4}>
					<Tooltip title={t("levelTips")}>
						<Typography variant='body2'>{t("level")}</Typography>
					</Tooltip>
				</Grid>
				<Grid>
					<FormControl size='small'>
						<Select
							sx={{ minWidth: selectWidth }}
							value={level}
							onChange={onSelectLevelChange}
						>
							{userLevel?.map((m: string, i: number) => {
								return (
									<MenuItem value={m} key={i}>
										<Typography variant='body2'>{t(m)}</Typography>
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</Grid>
			</Grid>
		);
	}

	function RowCalendar() {
		return (
			<Grid container sx={{ flexGrow: 1, alignItems: "center" }}>
				<Grid item xs={4}>
					<Tooltip title={t("calendarTips")}>
						<Typography variant='body2'>{t("calendar")}</Typography>
					</Tooltip>
				</Grid>
				<Grid>
					<FormControl size='small'>
						<Select
							sx={{ minWidth: selectWidth }}
							value={calendar}
							onChange={onCalendarChange}
						>
							{calendars?.map((m: ICalendar, i: number) => {
								console.log(m.id);
								return (
									<MenuItem value={m.id} key={i}>
										<Typography variant='body2'>{t(m.description)}</Typography>
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</Grid>
			</Grid>
		);
	}

	function RowDoors() {
		return (
			<Grid container sx={{ flexGrow: 1, alignItems: "center" }}>
				<Grid item xs={4}>
					<Tooltip title={t("visualizationType")}>
						<Typography variant='body2'>{t("gates")}</Typography>
					</Tooltip>
				</Grid>
				<Grid>
					<FormControl size='small'>
						<Select
							sx={{ minWidth: selectWidth }}
							value={showGates}
							onChange={onShowGateChange}
						>
							{showType?.map((m: string, i: number) => {
								return (
									<MenuItem value={m} key={i}>
										<Typography variant='body2'>{t(m)}</Typography>
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</Grid>
			</Grid>
		);
	}

	function RowAlarms() {
		return (
			<Grid container sx={{ flexGrow: 1, alignItems: "center" }}>
				<Grid item xs={4}>
					<Tooltip title={t("visualizationType")}>
						<Typography variant='body2'>{t("alarms")}</Typography>
					</Tooltip>
				</Grid>
				<Grid>
					<FormControl size='small'>
						<Select
							sx={{ minWidth: selectWidth }}
							value={showAlarms}
							onChange={onShowAlarmChange}
						>
							{showType?.map((m: string, i: number) => {
								return (
									<MenuItem value={m} key={i}>
										<Typography variant='body2'>{t(m)}</Typography>
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</Grid>
			</Grid>
		);
	}

	function RegistryCard() {
		return (
			<CustomCard>
				<CardHeader
					title={`${user?.firstName} ${user?.lastName}`}
					avatar={
						<IconButton aria-label='settings'>
							<Avatar src={getPicture(user)} />
						</IconButton>
					}
				/>
				<CardContent>
					<Grid container sx={{ flexGrow: 1 }}>
						<Grid item xs={3}>
							<Typography variant='body2'>{t("firstName")}</Typography>
						</Grid>
						<Grid>
							<Typography variant='body2'>{user?.firstName}</Typography>
						</Grid>
					</Grid>
					<Grid container sx={{ flexGrow: 1 }}>
						<Grid item xs={3}>
							<Typography variant='body2'>{t("lastName")}</Typography>
						</Grid>
						<Grid>
							<Typography variant='body2'>{user?.lastName}</Typography>
						</Grid>
					</Grid>
					<Grid container sx={{ flexGrow: 1 }}>
						<Grid item xs={3}>
							<Typography variant='body2'>{t("email")}</Typography>
						</Grid>
						<Grid item xs={7}>
							<Typography variant='body2'>{user?.email}</Typography>
						</Grid>
					</Grid>
					<Grid container sx={{ flexGrow: 1 }}>
						<Grid item xs={3}>
							<Typography variant='body2'>{t("state")}</Typography>
						</Grid>
						<Grid item xs={7}>
							<Typography variant='body2'>
								{user.enabled ? t("isEnabled") : t("disabled")}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
			</CustomCard>
		);
	}
}
