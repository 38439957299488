import {
	Box,
	Checkbox,
	MenuItem,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Value } from "react-multi-date-picker";
import { ICalendarRange } from "../interface/Entities";
import { RangeType } from "../interface/Enums";
import RecursiveCalendar from "./RecursiveCalendar";
import SingleCalendar from "./SingleCalendar";

interface RangeDetailProps {
	range: ICalendarRange;
	onChange: (range: ICalendarRange) => void;
}

export default function RangeDetail({ range, onChange }: RangeDetailProps) {
	const { t } = useTranslation();
	const theme = useTheme();

	return (
		<Box
			sx={{
				backgroundColor: theme.palette.background.default,
				paddingTop: 2,
				paddingBottom: 2,
				padding: 5,
				minHeight: "320px",
			}}
		>
			<Box
				sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
			>
				<TextField
					size='small'
					sx={{ padding: 1, minWidth: 120 }}
					label={<Typography variant='caption'>{t("rangeName")}</Typography>}
					value={range?.description}
					onChange={onDescriptionChange}
					inputProps={{ style: { fontSize: 14 } }}
				/>
				<TextField
					size='small'
					sx={{ padding: 1, minWidth: 140 }}
					label={
						<Typography variant='caption'>{t("rangeOperation")}</Typography>
					}
					value={range?.calendarOperation}
					onChange={onOperationChange}
					select
				>
					{[t("include"), t("exclude")].map((v, i) => (
						<MenuItem key={i.toString()} value={i}>
							{<Typography variant='body2'>{v}</Typography>}
						</MenuItem>
					))}
				</TextField>

				<TextField
					size='small'
					sx={{ padding: 1, minWidth: 160 }}
					label={<Typography variant='caption'>{t("RangeType")}</Typography>}
					value={range?.rangeType}
					onChange={onRangeTypeChange}
					select
				>
					{[t("recursive"), t("single")].map((v, i) => (
						<MenuItem key={i.toString()} value={i}>
							{<Typography variant='body2'>{v}</Typography>}
						</MenuItem>
					))}
				</TextField>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					<Typography variant='caption'>{t("enabled")}</Typography>
					<Checkbox
						size='small'
						aria-label='enabled'
						checked={range?.enabled}
						onChange={(v) => {
							onEnableChange(v);
						}}
					/>
				</Box>
			</Box>
			{range?.rangeType === RangeType.Recursive && (
				<RecursiveCalendar
					daysOfWeek={range.daysOfWeek}
					timeRange={range.timeRange}
					dateRange={range.startEndDateRange}
					onTimeChange={(v) => onTimeRangeChange(v)}
					onDaysOfWeekChange={(v) => onDaysWeekChange(v)}
					onDateChange={(v) => onStartEndDateChange(v)}
				/>
			)}
			{range?.rangeType === RangeType.Single && (
				<SingleCalendar
					dateRange={range.dateRange}
					timeRange={range.timeRange}
					onTimeChange={(v) => onTimeRangeChange(v)}
					onDateChange={(v) => onDateChange(v)}
				/>
			)}
		</Box>
	);

	function onOperationChange(v: any) {
		var b: ICalendarRange = {
			...range,
			calendarOperation: +v.target.value,
		} as ICalendarRange;
		onChange(b);
	}
	function onDescriptionChange(v: any) {
		var b: ICalendarRange = {
			...range,
			description: v.target.value,
		} as ICalendarRange;
		onChange(b);
	}
	function onRangeTypeChange(v: any) {
		var b: ICalendarRange = {
			...range,
			rangeType: +v.target.value,
		} as ICalendarRange;
		onChange(b);
	}

	function onEnableChange(v: any) {
		var b: ICalendarRange = {
			...range,
			enabled: v.target.checked,
		} as ICalendarRange;
		onChange(b);
	}

	function onTimeRangeChange(v: Value) {
		var b: ICalendarRange = {
			...range,
			timeRange: v,
		} as ICalendarRange;
		onChange(b);
	}

	function onStartEndDateChange(v: Value) {
		var b: ICalendarRange = {
			...range,
			startEndDateRange: v,
		} as ICalendarRange;
		onChange(b);
	}

	function onDateChange(v?: Value) {
		console.log("ondatechange", JSON.stringify(v));
		var b: ICalendarRange = {
			...range,
			dateRange: v,
		} as ICalendarRange;
		onChange(b);
	}
	function onDaysWeekChange(v: Value) {
		var b: ICalendarRange = {
			...range,
			daysOfWeek: v,
		} as ICalendarRange;
		onChange(b);
	}
}
