import { Box, Button, TextField, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface NewCalendarProps {
	onConfirm?: (name: string) => void;
	onCancel?: () => void;
}
export default function NewCalendar({ onConfirm, onCancel }: NewCalendarProps) {
	const [name, setName] = useState<string>("");
	const [confirmDisabled, setConfirmDisabled] = useState<boolean>(true);
	const { t } = useTranslation();
	const theme = useTheme();

	useEffect(() => {
		setConfirmDisabled(name?.length == 0);
	}, [name]);
	return (
		<Box
			sx={{
				backgroundColor: theme.palette.background.default,
				paddingTop: 2,
				paddingBottom: 2,
				padding: 5,
				minHeight: "200px",
				minWidth: "300px",
			}}
		>
			<TextField
				label={t("newCalendar")}
				value={name}
				onChange={(t) => setName(t.target.value)}
			/>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-end",
					marginTop: 3,
				}}
			>
				<Button
					disabled={confirmDisabled}
					sx={{ marginRight: 1 }}
					variant='contained'
					onClick={() => {
						if (onConfirm) onConfirm(name);
					}}
				>
					{t("confirm")}
				</Button>
				<Button
					color='warning'
					variant='contained'
					onClick={() => {
						if (onCancel) onCancel();
					}}
				>
					{t("cancel")}
				</Button>
			</Box>
		</Box>
	);
}
