import {
	Container,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { IGate } from "../interface/Entities";

interface GatesEnableTableProps {
	gates: IGate[];
}
export default function GatesEnableTable({ gates }: GatesEnableTableProps) {
	const theme = useTheme();
	const { t } = useTranslation();
	return (
		<Container>
			<TableContainer
				sx={{
					minHeight: "15vh",
					maxHeight: "25vh",
					overflowY: "false",
					marginTop: 1,
				}}
			>
				<Table stickyHeader aria-label='sticky table' size='small'>
					<TableHead>
						<TableRow>
							<TableCell
								style={{
									background: theme.palette.primary.dark,
									color: theme.palette.common.white,
								}}
							>
								{t("zone")}
							</TableCell>
							<TableCell
								style={{
									background: theme.palette.primary.dark,
									color: theme.palette.common.white,
								}}
							>
								{t("name")}
							</TableCell>
							<TableCell
								style={{
									background: theme.palette.primary.dark,
									color: theme.palette.common.white,
								}}
							>
								{t("description")}
							</TableCell>
							<TableCell
								style={{
									background: theme.palette.primary.dark,
									color: theme.palette.common.white,
								}}
							>
								{t("Active")}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{gates?.map((row) => {
							return (
								<TableRow>
									<TableCell>
										{" "}
										<Typography
											variant='inherit'
											color={row.active ? "CaptionText" : "coral"}
										>
											{row.zone}
										</Typography>
									</TableCell>
									<TableCell>
										{" "}
										<Typography
											variant='inherit'
											color={row.active ? "CaptionText" : "coral"}
										>
											{row.name}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography
											variant='inherit'
											color={row.active ? "CaptionText" : "coral"}
										>
											{row.friendlyName}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography
											variant='inherit'
											color={row.active ? "CaptionText" : "coral"}
										>
											{row.active ? t("yes") : t("no")}
										</Typography>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Container>
	);
}
