import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import {
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	styled,
	tableCellClasses,
	useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { IGate } from "../interface/Entities";

interface IGatesTableProps {
	gates: IGate[];
	onOpenGate: (zone: string, name: string, description: string) => void;
}

export default function GatesTable({
	gates,
	onOpenGate,
}: IGatesTableProps): JSX.Element {
	const { t } = useTranslation();
	const theme = useTheme();

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.common.white,
			fontSize: 16,
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 16,
		},
	}));

	return (
		<TableContainer
			sx={{
				minHeight: "75vh",
				maxHeight: "75vh",
				overflowY: "false",
				marginTop: 1,
			}}
		>
			<Table stickyHeader aria-label='sticky table' size='small'>
				<TableHead>
					<TableRow>
						<StyledTableCell
							sx={{ display: { xs: "none", md: "table-cell" } }}
							style={{
								background: theme.palette.primary.dark,
								color: theme.palette.common.white,
							}}
						>
							{t("zone")}
						</StyledTableCell>
						<StyledTableCell sx={{ display: { xs: "none", md: "table-cell" } }}>
							{t("name")}
						</StyledTableCell>
						<StyledTableCell>{t("description")}</StyledTableCell>
						<StyledTableCell>{t("openDoor")}</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{gates.map((row) => {
						return (
							<TableRow
								hover
								role='checkbox'
								tabIndex={-1}
								key={row.name + row.zone}
							>
								<TableCell sx={{ display: { xs: "none", md: "table-cell" } }}>
									{row.zone}
								</TableCell>
								<TableCell sx={{ display: { xs: "none", md: "table-cell" } }}>
									{row.name}
								</TableCell>
								<TableCell>{row.friendlyName}</TableCell>
								<TableCell>
									{row.active ? (
										<Button
											variant='outlined'
											startIcon={<MeetingRoomIcon />}
											onClick={(e) => {
												onOpenGate(row.zone, row.name, row.friendlyName);
											}}
										>
											{t("open") ?? "open"}
										</Button>
									) : (
										<>{t("suspended") ?? "suspended"}</>
									)}
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
