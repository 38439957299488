import { Box, Button, useTheme } from "@mui/material";
import TimePicker from "react-multi-date-picker/plugins/analog_time_picker";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "react-keycloak-manager";
import { Calendar, DateObject } from "react-multi-date-picker";
import {
	checkPeriod,
	getGatesEnabled,
	getTestGatesEnabled,
} from "../libraries/RestManager";
import { IDayToCheck, IDaysToCheck } from "../interface/Entities";

export default function TestPage() {
	const { t } = useTranslation();
	const { palette } = useTheme();
	const [bgCalendar, setBgCalendar] = useState("bg-white");
	const keyCloak = useKeycloak();

	const months = [
		"jan",
		"feb",
		"mar",
		"apr",
		"may",
		"jun",
		"jul",
		"aug",
		"sep",
		"oct",
		"nov",
		"dec",
	].map((v) => t(v));

	const weekDays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"].map((d) =>
		t(d),
	);
	const [today, setToday] = useState<DateObject>(new DateObject());

	useEffect(() => {
		var bgColor = palette.mode === "dark" ? "bg-dark" : "";
		setBgCalendar(bgColor);
	}, [palette.mode]);
	return (
		<Box>
			<Calendar
				className={bgCalendar}
				numberOfMonths={1}
				value={today}
				plugins={[<TimePicker hideSeconds position='right' format='HH:mm' />]}
				months={months}
				weekDays={weekDays}
				weekStartDayIndex={1}
				onChange={(date) => {
					setToday(date as DateObject);
				}}
			/>
			<Button
				onClick={async () => {
					var dayToCheck: IDayToCheck = {
						day: today.toDate(),
						disabled: false,
						doors: [],
					};
					var listdaysToCheck: IDaysToCheck = {
						days: [dayToCheck],
						today: today,
						calendar: 1,
					};
					var result = await checkPeriod(keyCloak, listdaysToCheck);
					console.log(result);
				}}
			>
				Check Period
			</Button>
			<Button
				onClick={async () => {
					var result = await getTestGatesEnabled(keyCloak, today.toDate());
					console.log(result);
				}}
			>
				Get Enabled Gates
			</Button>
		</Box>
	);
}
