import { useCallback, useEffect, useState } from "react";
import { IKeyCloakUser, useKeycloak } from "react-keycloak-manager";
import UserTable from "../components/UsersTable";

export default function Users() {
	const { getUsers, isAuthenticated } = useKeycloak();
	const [users, setUsers] = useState<IKeyCloakUser[]>([]);
	const [refresh, setRefresh] = useState(0);

	const fetchUsers = useCallback(async () => {
		if (isAuthenticated) {
			var user = await getUsers();
			setUsers(user ?? []);
		}
	}, [isAuthenticated]);

	useEffect(() => {
		fetchUsers();
	}, [fetchUsers, refresh]);

	return (
		<UserTable
			users={users}
			onRefreshTable={() => {
				setRefresh(Date.now());
			}}
		/>
	);
}
