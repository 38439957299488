export const userLevel = ["guest", "user", "superUser", "admin"];
export type UserLevel = typeof userLevel;
export enum UserEnum {
	Guest = 0,
	User = 1,
	SuperUser = 2,
	Admin = 3,
}

export const showType = ["hide", "test", "show"];
export type ShowType = typeof showType;
export enum ShowTypeEnum {
	Hide = 0,
	Test = 1,
	Show = 2,
}

export enum OperationType {
	Sum = 0,
	Subtract = 1,
}

export enum RangeType {
	Recursive = 0,
	Single = 1,
}
