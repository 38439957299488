import {
	Checkbox,
	Container,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "react-keycloak-manager";
import { ICalendarRange, IGate } from "../interface/Entities";
import { getGates } from "../libraries/RestManager";
import { OperationType } from "../interface/Enums";

interface RangeDetailProps {
	range: ICalendarRange;
	onChange: (range: ICalendarRange) => void;
}
export default function RangeDetailDoors({
	range,
	onChange,
}: RangeDetailProps) {
	const theme = useTheme();
	const { t } = useTranslation();
	const keyCloak = useKeycloak();
	const { isAuthenticated } = keyCloak;
	const [gates, setGates] = useState<IGate[]>([]);

	const fetchGates = useCallback(async () => {
		if (isAuthenticated) {
			var gates = await getGates(keyCloak);
			setGates(gates ?? []);
		} else setGates([]);
	}, [isAuthenticated]);

	useEffect(() => {
		fetchGates();
	}, [fetchGates, isAuthenticated]);

	function isChecked(row: IGate): boolean | undefined {
		var key = combineKey(row);
		return range.doors?.find((r) => r.key == key) !== undefined;
	}

	function onCheckboxChanged(row: IGate): void {
		var key = combineKey(row);
		var index = range.doors?.findIndex((r) => r.key == key);
		var r = { ...range };
		if (!r.doors) r.doors = [];
		if (index == undefined || index < 0)
			r.doors.push({ key: key, active: true });
		else r.doors.splice(index, 1);

		onChange(r);
	}

	function onOperationChange(v: any) {
		var b: ICalendarRange = {
			...range,
			doorsOperation: +v.target.value,
		} as ICalendarRange;
		onChange(b);
	}

	function combineKey(zone: IGate): string {
		return zone.zone + "-" + zone.name;
	}

	return (
		<Container style={{ padding: 12 }}>
			<TextField
				size='small'
				sx={{ padding: 1, minWidth: 140 }}
				label={<Typography variant='caption'>{t("rangeOperation")}</Typography>}
				value={range?.doorsOperation}
				onChange={onOperationChange}
				select
			>
				{[t("include"), t("exclude")].map((v, i) => (
					<MenuItem key={i.toString()} value={i}>
						{<Typography variant='body2'>{v}</Typography>}
					</MenuItem>
				))}
			</TextField>
			<TableContainer
				sx={{
					overflowY: "auto",
					marginTop: 1,
				}}
			>
				<Table stickyHeader aria-label='sticky table' size='small'>
					<TableHead>
						<TableRow>
							<TableCell
								style={{
									background: theme.palette.primary.dark,
									color: theme.palette.common.white,
								}}
							>
								{t("zone")}
							</TableCell>
							<TableCell
								style={{
									background: theme.palette.primary.dark,
									color: theme.palette.common.white,
								}}
							>
								{t("name")}
							</TableCell>
							<TableCell
								style={{
									background: theme.palette.primary.dark,
									color: theme.palette.common.white,
								}}
							>
								{t("description")}
							</TableCell>
							<TableCell
								style={{
									background: theme.palette.primary.dark,
									color: theme.palette.common.white,
								}}
							>
								{t("enabled")}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{gates.map((row) => {
							return (
								<TableRow>
									<TableCell>{row.zone}</TableCell>
									<TableCell>{row.name}</TableCell>
									<TableCell>{row.friendlyName}</TableCell>
									<TableCell>
										<Checkbox
											checked={isChecked(row)}
											onChange={(e) => onCheckboxChanged(row)}
										/>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Container>
	);
}
