import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import DateRangeIcon from "@mui/icons-material/DateRange";
import SensorDoorIcon from "@mui/icons-material/SensorDoor";
import TuneIcon from "@mui/icons-material/Tune";
import { Box, Button } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	AttributeType,
	IKeyCloakUser,
	useKeycloak,
} from "react-keycloak-manager";
import { IKeyCloakGroup } from "react-keycloak-manager/dist/Entities";
import { Link, useSearchParams } from "react-router-dom";
import { DoorGroup, UserGroup, VppGroupType } from "../interface/Constants";
import Tabber, { ITabPanel } from "../libraries/Tabber";
import UserCalendars from "./UserCalendars";
import UserDoorCalendarTest from "./UserDoorCalendarTest";
import UserGates from "./UserGates";
import UserMaster from "./UserMaster";

export default function User() {
	const [searchParams] = useSearchParams();
	const [user, setUser] = useState<IKeyCloakUser>({} as IKeyCloakUser);
	const [group, setGroup] = useState<IKeyCloakGroup>({} as IKeyCloakGroup);
	const [checkedGroups, setCheckedGroups] = useState<IKeyCloakGroup[]>([]);
	const [saveGroups, setSaveGroups] = useState<boolean>(false);
	const { t } = useTranslation();
	const {
		getUser,
		createGroup,
		updateGroupAttribute,
		findGroupsByName,
		assignUserToGroup,
		getUserGroups,
		deleteUserFromGroup,
	} = useKeycloak();
	const [saveDisabled, setSaveDisabled] = useState(true);

	const id = searchParams.get("id");

	const fetchUser = useCallback(async () => {
		if (id) {
			var user = await getUser(id);
			if (user?.username) {
				var cleanName = user?.username.replace(/[.@]/g, "");
				var usrGroups = await findGroupsByName(cleanName, false);
				if (usrGroups?.length == 0)
					usrGroups = await createUserGroup(cleanName, usrGroups, user);

				if (usrGroups?.length >= 1) {
					var usrGroup: IKeyCloakGroup = usrGroups[0];
					if (!usrGroup.attributes) usrGroup.attributes = {} as AttributeType;
					setGroup(usrGroup);
					await assignUserToGroup(user?.id, usrGroups[0].id!);
				}
			}
			const userGroup = await getUserGroups(id, false);
			const doorGroup = userGroup.filter(
				(g) =>
					g?.attributes &&
					g?.attributes[VppGroupType] &&
					g?.attributes[VppGroupType][0] === DoorGroup,
			);
			setCheckedGroups(doorGroup);

			if (user) setUser(user);
		}
	}, [id]);
	useEffect(() => {
		fetchUser();
	}, [fetchUser]);

	const onSave = async (id: string) => {
		if (saveGroups) await saveUserGroup(id);
		if (group?.attributes) await updateGroupAttribute(group, group?.attributes);
	};

	const saveUserGroup = async (id: string) => {
		// remove groups
		const userGroup = await getUserGroups(id, false);
		userGroup
			.filter(
				(g) =>
					g?.attributes &&
					g?.attributes[VppGroupType] &&
					g?.attributes[VppGroupType][0] === DoorGroup,
			)
			.forEach(async (group) => {
				const found =
					checkedGroups.find((g) => g.name == group.name) !== undefined;
				if (!found) await deleteUserFromGroup(user.id, group.id!);
			});

		// add groups
		checkedGroups.forEach(async (group) => {
			const found = userGroup.find((g) => g.name == group.name) !== undefined;
			if (!found) await assignUserToGroup(user.id, group.id!);
		});
	};

	const onChange = (checked?: IKeyCloakGroup[]) => {
		if (checked) {
			setCheckedGroups(checked);
		}
		setSaveGroups(true);
		setSaveDisabled(false);
	};

	function onChangeUserGroup(group: IKeyCloakGroup): void {
		setGroup(group);
		setSaveDisabled(false);
	}

	const tabs: ITabPanel[] = [
		{
			title: t("registry"),
			panel: (
				<UserMaster
					group={group}
					user={user}
					userGroups={checkedGroups}
					onChange={(g) => onChange(g)}
				/>
			),
			icon: <AssignmentIndIcon />,
		},
		{
			title: t("gates"),
			panel: <UserGates group={group} onChange={onChange} />,
			icon: <SensorDoorIcon />,
		},
		{
			title: t("ranges"),
			panel: <UserCalendars group={group} onChange={onChangeUserGroup} />,
			icon: <DateRangeIcon />,
		},
		{
			title: t("test"),
			panel: (
				<UserDoorCalendarTest
					user={user}
					userGroup={group}
					checkedGroup={checkedGroups}
				/>
			),
			icon: <TuneIcon />,
		},
	];

	return (
		<Box>
			<Tabber panels={tabs} />
			<Box sx={{ justifyContent: "right", display: "flex", columnGap: 1 }}>
				<Button
					variant='contained'
					disabled={saveDisabled}
					onClick={() => onSave(id!)}
					component={Link}
					to='/users'
				>
					{t("save")}
				</Button>
				<Button
					color='warning'
					variant='contained'
					component={Link}
					to='/users'
				>
					{t("cancel")}
				</Button>
			</Box>
		</Box>
	);

	async function createUserGroup(
		cleanName: string,
		usrGroups: IKeyCloakGroup[],
		user: IKeyCloakUser,
	) {
		var attributes: AttributeType = {} as AttributeType;
		attributes[VppGroupType] = [UserGroup];
		await createGroup(cleanName, attributes);
		usrGroups = await findGroupsByName(cleanName, false);
		await assignUserToGroup(user?.id, usrGroups[0].id!);
		return usrGroups;
	}
}
