import { Box, Container, Modal } from "@mui/material";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "react-keycloak-manager";
import { useSearchParams } from "react-router-dom";
import { ConfirmDialog } from "react-vevy-library";
import RangesTable from "../components/RangesTable";
import TextEditSave from "../components/TextEditSave";
import { ICalendar, ICalendarRange } from "../interface/Entities";
import { OperationType, RangeType } from "../interface/Enums";
import { loadCalendar, saveCalendar } from "../libraries/RestManager";
import CalendarRangeEdit from "./CalendarRangeEdit";

export default function Calendar() {
	const [searchParams] = useSearchParams();
	const { t } = useTranslation();
	const [calendar, setCalendar] = useState<ICalendar>();
	const [range, setRange] = useState<ICalendarRange>({} as ICalendarRange);
	const [rangeIndex, setRangeIndex] = useState<number>(0);
	const [showRange, setShowRange] = useState<boolean>(false);
	const [refresh, setRefresh] = useState<number>(0);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [maxId, setMaxId] = useState(0);

	const keyCloak = useKeycloak();

	const id = searchParams.get("id");

	const fetchCalendars = useCallback(
		async (id: string) => {
			var cal = await loadCalendar(keyCloak, id);
			if (cal) setCalendar(cal);
		},
		[id, refresh],
	);

	useEffect(() => {
		if (calendar !== undefined) {
			var max =
				calendar.ranges?.reduce<number>(
					(acc, r) => (r?.id > acc ? r?.id ?? 0 : acc),
					0,
				) ?? 0;
			setMaxId(max);
		}
	}, [calendar]);

	useEffect(() => {
		if (id) {
			fetchCalendars(id);
		}
	}, [id, refresh]);

	async function onSaveCalendarDesc(txt: string): Promise<void> {
		var cal: ICalendar = { ...calendar } as ICalendar;
		cal.description = txt;
		setCalendar(cal);
		await saveCalendar(keyCloak, cal);
	}

	return (
		<Container
			maxWidth={false}
			sx={{
				flexDirection: "row",
				height: "70vh",
			}}
		>
			<TextEditSave
				text={calendar?.description}
				onConfirm={onSaveCalendarDesc}
			/>
			<Box>
				<RangesTable
					ranges={calendar?.ranges}
					onEdit={(range: ICalendarRange, index: number) => {
						setRangeIndex(index);
						setRange(range);
						setShowRange(true);
					}}
					onAdd={() => {
						var range: ICalendarRange = {
							id: -1,
							calendarOperation: OperationType.Sum,
							enabled: false,
							rangeType: RangeType.Recursive,
							description: "",
							doorsOperation: OperationType.Sum,
							doors: [],
						};
						setRange(range);
						setShowRange(true);
					}}
					onDelete={async (i) => {
						setRange(i);
						setDialogOpen(true);
					}}
				/>
			</Box>
			<Box>
				<Modal
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
					onClose={(e, r) => {
						console.log(r);
						setShowRange(false);
					}}
					open={showRange}
				>
					<Fragment>
						<CalendarRangeEdit
							range={range}
							onCancel={() => {
								setShowRange(false);
							}}
							onConfirm={async (c) => {
								setShowRange(false);
								setRange(c);
								await saveCalendarRange(c, rangeIndex);
								setRefresh(Date.now);
							}}
						/>
					</Fragment>
				</Modal>
			</Box>
			<ConfirmDialog
				title={`${t("deleteRange")}`}
				content={`${t("deleteRangeMessage")} ${range?.description}`}
				open={dialogOpen}
				actionOk={async () => {
					setDialogOpen(false);
					if (range) {
						await deleteCalendarRange(range);
						setRefresh(Date.now);
					}
				}}
				actionCancel={() => {
					setDialogOpen(false);
				}}
			/>
		</Container>
	);

	async function saveCalendarRange(
		c: ICalendarRange,
		index: number,
	): Promise<string> {
		c = cleanRange(c);
		if (c.id === -1) {
			c.id = maxId + 1;
			setMaxId(maxId + 1);
			calendar?.ranges?.push(c);
		} else {
			// var index = calendar?.ranges?.findIndex((r) => r.id == c.id);
			if (index !== undefined && index >= 0 && calendar?.ranges)
				calendar.ranges[index] = c;
		}
		return await saveCalendar(keyCloak, calendar!);
	}

	function cleanRange(range: ICalendarRange): ICalendarRange {
		switch (range.rangeType) {
			case RangeType.Recursive:
				range.dateRange = undefined;
				break;
			case RangeType.Single:
				range.daysOfWeek = "";
				range.startEndDateRange = undefined;
				break;
		}
		return range;
	}

	async function deleteCalendarRange(range: ICalendarRange): Promise<string> {
		var index = calendar?.ranges?.findIndex((r) => r.id == range.id);
		if (index !== undefined && index >= 0 && calendar?.ranges)
			calendar.ranges.splice(index, 1);

		return await saveCalendar(keyCloak, calendar!);
	}
}
