import DateRangeIcon from "@mui/icons-material/DateRange";
import { useTranslation } from "react-i18next";
import Tabber, { ITabPanel } from "../libraries/Tabber";
import CalendarEdit from "./CalendarEdit";
import { Fragment } from "react";
import { Box, Button, Tooltip } from "@mui/material";
import CalendarTest from "./CalendarTest";
import TuneIcon from "@mui/icons-material/Tune";

export default function Calendar() {
	const { t } = useTranslation();
	const tabs: ITabPanel[] = [
		{
			title: t("calendar"),
			panel: <CalendarEdit />,
			icon: <DateRangeIcon />,
		},
		{
			title: t("test"),
			panel: <CalendarTest />,
			icon: <TuneIcon />,
		},
	];

	return (
		<Fragment>
			<Tabber panels={tabs} />{" "}
			<Box display='flex' justifyContent='flex-end'>
				<Tooltip title={t("newCalendarText")}>
					<Button variant='contained' href='/calendars'>
						{t("backToCalendar")}{" "}
					</Button>
				</Tooltip>
			</Box>
		</Fragment>
	);
}
