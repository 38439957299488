import KeyIcon from "@mui/icons-material/Key";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsOffIcom from "@mui/icons-material/NotificationsOff";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {
	Collapse,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	styled,
	tableCellClasses,
	useTheme,
} from "@mui/material";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { IAlarm, IDisplayAlarm } from "../interface/Entities";

interface IAlarmsTableProps {
	alarms: IDisplayAlarm[];
}

export default function AlarmsTable({ alarms }: IAlarmsTableProps) {
	const { t } = useTranslation();
	const theme = useTheme();

	const StyledTitleCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.body}`]: {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.common.white,
			fontSize: 16,
		},
	}));

	const StyledBodyCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.body}`]: {},
	}));

	const GetIcon = (row: IAlarm) => {
		switch (row.category) {
			case "DigitalKey":
				return row.status ? (
					<NotificationsIcon color='error' />
				) : (
					<NotificationsOffIcom color='success' />
				);
			case "Intrusion":
				return row.status ? <ReportProblemIcon color='error' /> : null;
			case "SecurityLock":
				return row.status ? (
					<KeyIcon color='error' />
				) : (
					<MeetingRoomIcon color='success' />
				);
		}
	};

	const AlarmsRow = ({ row }: { row: IDisplayAlarm }) => {
		const [open, setOpen] = useState<boolean>(true);
		return (
			<Fragment>
				<TableRow>
					<StyledTitleCell style={{ width: 10 }}>
						<IconButton
							disabled={false}
							aria-label='expand row'
							size='small'
							onClick={() => {
								console.log("qua");
								setOpen(!open);
							}}
						>
							{open ? (
								<KeyboardArrowUpIcon
									sx={{ color: theme.palette.common.white }}
								/>
							) : (
								<KeyboardArrowDownIcon
									sx={{ color: theme.palette.common.white }}
								/>
							)}
						</IconButton>
					</StyledTitleCell>
					<StyledTitleCell>{t(row.friendlyName)}</StyledTitleCell>
				</TableRow>
				<TableRow key={`det${row.friendlyName}`}>
					<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
						<Collapse in={open} timeout='auto' unmountOnExit>
							<Table size='small' aria-label='purchases'>
								<TableBody>
									{row.alarms.map((alarm) => (
										<TableRow key={alarm.name}>
											<StyledBodyCell style={{ minWidth: 100, maxWidth: 200 }}>
												{alarm.friendlyName}
											</StyledBodyCell>
											<TableCell size='small'>{GetIcon(alarm)}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Collapse>
					</TableCell>
				</TableRow>
			</Fragment>
		);
	};

	return (
		<TableContainer
			sx={{
				minHeight: "75vh",
				maxHeight: "75vh",
				overflowY: "false",
				marginTop: 1,
			}}
		>
			<Table stickyHeader aria-label='sticky table'>
				<TableBody>
					{alarms.map((row) => (
						<Fragment key={row.friendlyName}>
							<AlarmsRow row={row} />
						</Fragment>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
