import { Box, Button, Container, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "react-keycloak-manager";
import AlarmsTable from "../components/AlarmsTable";
import { ExtendedProfile, IAlarm, IDisplayAlarm } from "../interface/Entities";
import { UserEnum, userLevel } from "../interface/Enums";
import settings from "../settings.json";
import { getAlarmsState, refreshAlarms } from "../libraries/RestManager";

export default function AlarmsPage() {
	const keyCloak = useKeycloak();
	const { authenticatedUser, isAuthenticated } = keyCloak;
	const { t } = useTranslation();
	const [alarms, setAlarms] = useState<IDisplayAlarm[]>([]);
	const [refreshDevices, setRefreshDevices] = useState(0);
	const [isAdmin, setIsAdmin] = useState<boolean>(false);

	const fetchAlarms = useCallback(async () => {
		var displayAlarm: IDisplayAlarm[] = [];
		var oldcategory: string = "";
		var alarmIndex = 0;
		if (isAuthenticated) {
			var _alarms = await getAlarmsState(keyCloak);

			_alarms.forEach((a) => {
				if (a.category !== oldcategory) {
					alarmIndex = displayAlarm.push({
						friendlyName: t(a.category) ?? a.category,
						alarms: [a],
					});
				} else {
					displayAlarm[alarmIndex - 1].alarms.push(a);
				}
				oldcategory = a.category;
			});

			setAlarms(displayAlarm);
		} else setAlarms([]);
	}, [isAuthenticated, refreshDevices]);

	const refreshDevice = async (e: any) => {
		await refreshAlarms(keyCloak);
		setRefreshDevices(Date.now());
	};

	useEffect(() => {
		var extUser = authenticatedUser?.profile as ExtendedProfile;
		setIsAdmin(
			extUser?.VppUserLevel === userLevel[UserEnum.Admin] ||
				extUser?.VppUserLevel === userLevel[UserEnum.SuperUser],
		);
	}, [isAuthenticated, authenticatedUser]);

	useEffect(() => {
		fetchAlarms();
	}, [fetchAlarms]);

	return (
		<Box>
			<AlarmsTable alarms={alarms} />
			{isAdmin && (
				<Box display='flex' justifyContent='flex-end'>
					<Tooltip title={t("refreshText")}>
						<Button variant='contained' onClick={refreshDevice}>
							{t("refresh")}{" "}
						</Button>
					</Tooltip>
				</Box>
			)}
		</Box>
	);
}
