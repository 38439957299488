import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
	Checkbox,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	styled,
	tableCellClasses,
	useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ICalendarRange } from "../interface/Entities";
import { RangeType, OperationType } from "../interface/Enums";
import { ReactNode } from "react";
import DateObject from "react-date-object";

interface RangesTableProps {
	ranges?: ICalendarRange[];
	onEdit?: (range: ICalendarRange, index: number) => void;
	onDelete?: (range: ICalendarRange) => void;
	onAdd?: () => void;
}
export default function RangesTable({
	ranges,
	onEdit,
	onAdd,
	onDelete,
}: RangesTableProps) {
	const theme = useTheme();
	const { t } = useTranslation();

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.common.white,
			fontSize: 16,
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 16,
		},
	}));

	function decodeRangeType(row: ICalendarRange): ReactNode {
		switch (row.rangeType) {
			case RangeType.Recursive:
				return decodeRecursive(row);
			case RangeType.Single:
				return decodeSingle(row);
		}
		return "decoder Todo";
	}

	function decodeRecursive(row: ICalendarRange): string {
		var weekDays = decodeWeek(row.daysOfWeek);
		var timeRange = decodeTimeRange(row.timeRange as DateObject[]);
		var dateRange = decodeDateRange(row.startEndDateRange as DateObject[]);

		return `${t("each")} ${weekDays} ${timeRange} ${dateRange}`;
	}

	function decodeSingle(row: ICalendarRange): string {
		var singleDate = decodeSingleDate(
			row.dateRange as unknown as DateObject[][],
		);
		var timeRange = decodeTimeRange(row.timeRange as DateObject[]);

		return `${singleDate} ${timeRange}`;
	}

	function decodeSingleDate(dateRange: DateObject[][] | undefined): string {
		var a =
			dateRange?.reduce<string>((acc: string, range, index) => {
				console.log("range", acc);
				// if (index === 0) acc = decodeRangeDate(acc as unknown as DateObject[]);

				return acc + (acc.length > 0 ? ", " : "") + decodeRangeDate(range);
			}, "") ?? "";

		return a;
	}

	function decodeRangeDate(dataRange: DateObject[]): string {
		if (dataRange[0]) {
			var from = new DateObject(dataRange[0]).format(
				t("calendarFormat") ?? "DD/MM/YY",
			);
			if (dataRange[1]) {
				var to = new DateObject(dataRange[1]).format(
					t("calendarFormat") ?? "DD/MM/YY",
				);
				return `${from} -> ${to}`;
			} else return `${from}`;
		}
		return "";
	}

	function decodeOperation(operation: OperationType): string {
		return operation === OperationType.Sum ? t("include") : t("exclude");
	}

	function decodeWeek(daysOfWeek?: string): string {
		return (
			daysOfWeek?.split(",").reduce((acc, day, index) => {
				return acc + " " + t(day);
			}, "") ?? ""
		);
	}

	function decodeDateRange(dateRange: DateObject[] | undefined): string {
		if (!dateRange) return "";

		if (dateRange[0]) {
			var from = new DateObject(dateRange[0]).format(
				t("calendarFormat") ?? "DD/MM/YY",
			);
			if (dateRange[1] !== undefined) {
				var to = new DateObject(dateRange[1]).format(
					t("calendarFormat") ?? "DD/MM/YY",
				);
				return `${t("fromDt")} ${from} ${t("toDt")} ${to}`;
			} else return `${t("fromDt")} ${from}`;
		} else {
			if (dateRange[1]) {
				var to1 = new DateObject(dateRange[1]).format(
					t("calendarFormat") ?? "DD/MM/YY",
				);
				return `${t("untilToDt")} ${to1}`;
			} else return "";
		}
	}

	function decodeTimeRange(timeRange: DateObject[] | undefined): string {
		if (!timeRange) return "";

		if (timeRange[0]) {
			var from = new DateObject(timeRange[0]).format("HH:mm");
			if (timeRange[1] !== undefined) {
				var to0 = new DateObject(timeRange[1]).format("HH:mm");
				return `${t("from")} ${from} ${t("to")} ${to0}`;
			} else return `${t("from")} ${from}`;
		} else {
			if (timeRange[1]) {
				var to1 = new DateObject(timeRange[1]).format("HH:mm");
				return `${t("untilTo")} ${to1}`;
			} else return "";
		}
	}

	return (
		<TableContainer
			sx={{
				minHeight: "70vh",
				maxHeight: "70vh",
				overflowY: "false",
				marginTop: 1,
			}}
		>
			<Table stickyHeader aria-label='sticky table' size='small'>
				<TableHead>
					<TableRow>
						<StyledTableCell>{t("rangeName")}</StyledTableCell>
						<StyledTableCell>{t("rangeOperation")}</StyledTableCell>
						<StyledTableCell>{t("description")}</StyledTableCell>
						<StyledTableCell>{t("enabled")}</StyledTableCell>
						<StyledTableCell>{""}</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{ranges?.map((row, index) => {
						return (
							<TableRow hover tabIndex={-1} key={index}>
								<TableCell>{row.description}</TableCell>
								<TableCell>{decodeOperation(row.calendarOperation)}</TableCell>
								<TableCell>{decodeRangeType(row)}</TableCell>
								<TableCell>
									<Checkbox color='primary' checked={row.enabled} />
								</TableCell>

								<TableCell>
									<Tooltip title={t("edit")}>
										<IconButton
											color='primary'
											onClick={() => {
												onEdit && onEdit(row, index);
											}}
										>
											<EditIcon />
										</IconButton>
									</Tooltip>
									<Tooltip title={t("delete")}>
										<IconButton
											color='primary'
											aria-label='delete'
											onClick={(e) => onDelete && onDelete(row)}
										>
											<DeleteIcon />
										</IconButton>
									</Tooltip>
								</TableCell>
							</TableRow>
						);
					})}
					<TableRow>
						<TableCell> </TableCell>
						<TableCell> </TableCell>
						<TableCell>{t("addNewRange")}</TableCell>
						<TableCell> </TableCell>
						<TableCell>
							<Tooltip title={t("add")}>
								<IconButton
									color='primary'
									aria-label='add'
									onClick={(e) => onAdd && onAdd()}
								>
									<AddIcon />
								</IconButton>
							</Tooltip>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
}
