import {
	Checkbox,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Typography,
} from "@mui/material";
import { IKeyCloakGroup } from "react-keycloak-manager";

interface ListCheckboxProps {
	listGroup: IKeyCloakGroup[];
	checked: IKeyCloakGroup[];
	setChecked: (checked: IKeyCloakGroup[]) => void;
}

export default function ListCheckbox({
	listGroup,
	checked,
	setChecked,
}: ListCheckboxProps) {
	function handleValue(group: IKeyCloakGroup): void {
		const currentIndex = checked.findIndex((g) => g.name === group.name);
		const newChecked = [...checked];
		if (currentIndex === -1) newChecked.push(group);
		else newChecked.splice(currentIndex, 1);

		setChecked(newChecked);
	}

	return (
		<List
			sx={{ width: "100%", maxWidth: 360, maxHeight: 200, overflow: "auto" }}
		>
			{listGroup.map((group, index) => {
				const labelId = `checkbox-list-label-${group.name}`;
				return (
					<ListItem
						key={`${index}`}
						component='div'
						disablePadding
						disableGutters
						onClick={() => handleValue(group)}
					>
						<ListItemButton dense>
							<Checkbox
								disableRipple
								edge='start'
								checked={checked.findIndex((g) => g.name === group.name) !== -1}
								tabIndex={-1}
								inputProps={{ "aria-labelledby": labelId }}
							/>
							<ListItemText
								id={labelId}
								primary={<Typography variant='body2'>{group.name}</Typography>}
							/>
						</ListItemButton>
					</ListItem>
				);
			})}
		</List>
	);
}
