import { Box, Button, Modal, Tooltip } from "@mui/material";
import { Fragment, useCallback, useEffect, useState } from "react";
import {
	deleteCalendar,
	getCalendars,
	saveCalendar,
} from "../libraries/RestManager";
import { ICalendar } from "../interface/Entities";
import { useKeycloak } from "react-keycloak-manager";
import CalendarsTable from "../components/CalendarsTable";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import NewCalendar from "../components/NewCalendar";
import { ConfirmDialog } from "react-vevy-library";

export default function Calendars() {
	const keyCloak = useKeycloak();
	const [refresh, setRefresh] = useState(0);
	const { t } = useTranslation();
	const [calendars, setCalendars] = useState<ICalendar[]>([]);
	const [showNewCalendar, setShowNewCalendar] = useState<boolean>(false);
	const [showDeleteCalendar, setShowDeleteCalendar] = useState(false);
	const [calendar, setCalendar] = useState<ICalendar>();
	const navigator = useNavigate();

	const fetchCalendars = useCallback(async () => {
		var calendarList = await getCalendars(keyCloak);
		setCalendars(calendarList ?? []);
	}, [refresh]);

	useEffect(() => {
		fetchCalendars();
	}, [fetchCalendars, refresh]);

	function onCloseDialog(): void {
		setShowDeleteCalendar(false);
	}

	async function onConfirmNewCalendar(name: string): Promise<void> {
		setShowNewCalendar(false);
		var calendar: ICalendar = {} as ICalendar;
		calendar.description = name;

		var newCal = await saveCalendar(keyCloak, calendar);
		var id = Number.parseInt(newCal);
		if (id !== -1) navigator(`/calendar?id=${id}`);
		setRefresh(Date.now());
	}

	async function onConfirmDeleteCalendar(): Promise<void> {
		await deleteCalendar(keyCloak, calendar!);
		onCloseDialog();
		setRefresh(Date.now());
	}

	return (
		<Box>
			<CalendarsTable
				calendars={calendars}
				onRefreshTable={() => {
					setRefresh(Date.now());
				}}
				onDeleteCalendar={(c) => {
					setCalendar(c);
					setShowDeleteCalendar(true);
				}}
			/>
			<Box display='flex' justifyContent='flex-end'>
				<Tooltip title={t("newCalendarText")}>
					<Button
						variant='contained'
						onClick={() => {
							setShowNewCalendar(true);
						}}
					>
						{t("newCalendar")}{" "}
					</Button>
				</Tooltip>
			</Box>
			<Modal
				aria-labelledby='modal-title'
				aria-describedby='modal-description'
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
				onClose={(e, r) => {
					console.log(r);
					// if (r === "backdropClick") return;
					setShowNewCalendar(false);
				}}
				open={showNewCalendar}
			>
				<Fragment>
					<NewCalendar
						onConfirm={onConfirmNewCalendar}
						onCancel={() => setShowNewCalendar(false)}
					/>
				</Fragment>
			</Modal>
			<ConfirmDialog
				open={showDeleteCalendar}
				title={t("deleteCalendarTitle")}
				content={`${t("deleteCalendarMessage")} ${calendar?.description}`}
				actionOk={onConfirmDeleteCalendar}
				actionCancel={onCloseDialog}
			/>
		</Box>
	);
}
