import { Box, Button, useTheme } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import DateObject from "react-date-object";
import { useTranslation } from "react-i18next";
import {
	IKeyCloakGroup,
	IKeyCloakUser,
	useKeycloak,
} from "react-keycloak-manager";
import { Calendar } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/analog_time_picker";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import GatesEnableTable from "../components/GatesEnableTable";
import { VppCalRange, VppCalendar, VppZoneName } from "../interface/Constants";
import {
	IDayToCheck,
	IDaysToCheck,
	IDoorStatus,
	IGate,
	IStateDay,
} from "../interface/Entities";
import { checkPeriod, getGates } from "../libraries/RestManager";

interface IUserDoorTestProps {
	user: IKeyCloakUser;
	userGroup: IKeyCloakGroup;
	checkedGroup: IKeyCloakGroup[];
}

export default function UserDoorCalendarTest({
	user,
	userGroup,
	checkedGroup,
}: IUserDoorTestProps) {
	const { t } = useTranslation();

	const months = [
		"jan",
		"feb",
		"mar",
		"apr",
		"may",
		"jun",
		"jul",
		"aug",
		"sep",
		"oct",
		"nov",
		"dec",
	].map((v) => t(v));

	const weekDays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"].map((d) =>
		t(d),
	);

	const [today, setToday] = useState<DateObject>(new DateObject());
	const [mapEnable, setMapEnable] = useState(new Map<string, IStateDay>());
	const [doorsEnabled, setDoorsEnabled] = useState<IGate[]>([]);
	const [todayDoors, setTodayDoors] = useState<IDoorStatus[]>([]);
	const keyCloak = useKeycloak();
	const { palette } = useTheme();
	const [bgCalendar, setBgCalendar] = useState("bg-white");
	const [groupGates, setGroupGates] = useState<IGate[] | null>([]);
	const [allGates, setAllGates] = useState<IGate[] | null>([]);
	const [rangeDays, setRangeDays] = useState<IDayToCheck[]>([]);

	useEffect(() => {
		var bgColor = palette.mode === "dark" ? "bg-dark" : "";
		setBgCalendar(bgColor);
	}, [palette.mode]);

	const fetchDoors = useCallback(async () => {
		var allGates = await getGates(keyCloak);
		var groupGates = await createGroupDoors(allGates);
		setAllGates(allGates);
		setGroupGates(groupGates);
	}, []);

	useEffect(() => {
		fetchDoors();
	}, [fetchDoors]);

	useEffect(() => {
		var currentGates: IGate[] = [];
		allGates?.forEach((g) => {
			const key = `${g.zone}-${g.name}`;
			var door = todayDoors.find((t) => t.key == key);
			if (door) currentGates.push({ ...g, active: door.active });
		});
		setDoorsEnabled([...currentGates]);
	}, [todayDoors]);

	useEffect(() => {
		const todayString = today.format("YYYYMMDD");
		var result = rangeDays.find(
			(r) => new DateObject(r.day).format("YYYYMMDD") == todayString,
		);
		if (result) setTodayDoors(result.doors ?? []);
	}, [today]);

	async function CheckCalendar() {
		var daysToCheck: IDaysToCheck = {
			today: today,
			days: [],
			ranges: [],
		};

		var doorList = groupGates?.map<IDoorStatus>((d) => ({
			key: `${d.zone}-${d.name}`,
			active: false,
		}));
		mapEnable.forEach((v, k) => {
			var dayToCheck: IDayToCheck = {
				day: v.day.toDate(),
				disabled: false,
				doors: doorList,
			};
			if (!v.checked) daysToCheck.days.push(dayToCheck);
		});

		daysToCheck.calendar = userGroup?.attributes
			? +userGroup?.attributes[VppCalendar][0]
			: undefined;
		var ranges: string[] = userGroup?.attributes
			? userGroup?.attributes[VppCalRange]
			: [];
		var toSave = ranges?.map((r) => JSON.parse(r));

		daysToCheck.ranges = toSave;
		const todayString = today.format("YYYYMMDD");
		var result = await checkPeriod(keyCloak, daysToCheck);
		setRangeDays(result ?? []);
		result?.forEach((r) => {
			var key = new DateObject(r.day).format("YYYYMMDD");
			var day = mapEnable.get(key);
			if (day) {
				mapEnable.set(key, {
					...day,
					checked: true,
					disabled: r.disabled,
				});
			}
			if (key == todayString) {
				setTodayDoors(r.doors ?? []);
			}
		});
		setToday(new DateObject(today));
	}

	async function createGroupDoors(allGates: IGate[] | null): Promise<IGate[]> {
		var userDoor: string[] = userGroup?.attributes
			? userGroup?.attributes[VppZoneName]
			: [];

		const doors: { [id: string]: number } = {};

		userDoor?.forEach((d) => (doors[d] = 1));
		checkedGroup?.forEach((g) => {
			if (g.attributes && g.attributes[VppZoneName]) {
				var groupDoors: string[] = g.attributes[VppZoneName];
				if (groupDoors) groupDoors.forEach((d) => (doors[d] = 1));
			}
		});

		var userGates: IGate[] = [];
		allGates?.forEach((door) => {
			var key = `${door.zone}-${door.name}`;
			if (doors[key] === 1) userGates.push(door);
		});

		return userGates;
	}

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				margin: 2,
			}}
		>
			<Calendar
				className={bgCalendar}
				numberOfMonths={3}
				value={today}
				plugins={[<TimePicker hideSeconds position='right' format='HH:mm' />]}
				onChange={(v) => {
					mapEnable.forEach((v, k) => {
						setMapEnable(
							mapEnable.set(k, {
								...v,
								checked: false,
							}),
						);
					});
					setToday(v as DateObject);
				}}
				months={months}
				weekDays={weekDays}
				mapDays={({ date }) => {
					var key = date.format("YYYYMMDD");
					if (mapEnable.has(key)) {
						var state = mapEnable.get(key);
						return {
							style: { color: state?.disabled ? "red" : undefined },
							disabled: false,
						};
					} else {
						setMapEnable(
							mapEnable.set(key, {
								day: date,
								disabled: false,
								checked: false,
							}),
						);
						return { disabled: false };
					}
				}}
				weekStartDayIndex={1}
			/>
			<Button
				sx={{ marginTop: 2 }}
				variant='contained'
				onClick={async () => {
					await CheckCalendar();
				}}
			>
				{t("check")}
			</Button>
			<GatesEnableTable gates={doorsEnabled} />
		</Box>
	);
}
