import { CssBaseline } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import Home from "./Home";
import settings from "../settings.json";

import { VevyThemeProvider } from "react-vevy-mui-theme";
import { VevyLibrary } from "react-vevy-library";

function App() {
	return (
		<VevyLibrary settings={settings}>
			<Home />
		</VevyLibrary>
	);
}

export default App;
