import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "react-keycloak-manager";
import { Route, Routes } from "react-router-dom";
import { ProtectedElement } from "../helpers/ProtectElement";
import { ExtendedProfile } from "../interface/Entities";
import { UserEnum, userLevel } from "../interface/Enums";
import AlarmsPage from "./AlarmsPage";
import Calendar from "./Calendar";
import Calendars from "./Calendars";
import DebugPage from "./DebugPage";
import GatesPage from "./GatesPage";
import Group from "./Group";
import Groups from "./GroupsPage";
import User from "./User";
import Users from "./Users";
import { MenuComposer } from "react-vevy-library";
import MainMenu, {
	IMenuPage,
} from "react-vevy-library/dist/libraries/MainMenu";
import TestPage from "./TestPage";

export default function Home(): JSX.Element {
	const { t } = useTranslation();
	const { authenticatedUser, isAuthenticated } = useKeycloak();

	const [menu, setMenu] = useState<IMenuPage[]>([]);
	const [level, setLevel] = useState<string>(userLevel[UserEnum.Guest]);
	const [showGates, setShowGates] = useState(false);
	const [showAlarms, setShowAlarms] = useState(false);

	useEffect(() => {
		if (isAuthenticated) {
			var extUser = authenticatedUser?.profile as ExtendedProfile;
			setShowGates(extUser?.VppGatesShow !== "hide");
			setShowAlarms(extUser?.VppAlarmsShow !== "hide");
			setLevel(extUser?.VppUserLevel ?? userLevel[UserEnum.Guest]);
		}
	}, [isAuthenticated, authenticatedUser]);

	useEffect(() => {
		var menu = MenuComposer([
			{
				menuItem: {
					title: t("gates"),
					link: "gate",
				},
				enabled: () => showGates,
			},
			{
				menuItem: {
					title: t("alarms"),
					link: "alarm",
				},
				enabled: () => showAlarms,
			},
			{
				menuItem: [
					{
						title: t("users"),
						link: "users",
					},
					{
						title: t("debug"),
						link: "debug",
					},
					{
						title: t("test"),
						link: "test",
					},
				],
				enabled: () => level === userLevel[UserEnum.Admin],
			},
			{
				menuItem: [
					{
						title: t("calendars"),
						link: "calendars",
					},
					{
						title: t("groups"),
						link: "groups",
					},
				],
				enabled: () =>
					level === userLevel[UserEnum.SuperUser] ||
					level === userLevel[UserEnum.Admin],
			},
		]);
		setMenu(menu);
	}, [level, showAlarms, showGates]);

	return (
		<Box sx={{ marginLeft: "1em", marginRight: "1em", marginTop: "1em" }}>
			<MainMenu title='Vevy Passepartout' pages={menu} />
			<Routes>
				<Route index element={<></>} />
				<Route
					path='gate'
					element={
						<ProtectedElement enabled={() => showGates}>
							<GatesPage />
						</ProtectedElement>
					}
				/>
				<Route
					path='alarm'
					element={
						<ProtectedElement enabled={() => showAlarms}>
							<AlarmsPage />
						</ProtectedElement>
					}
				/>
				<Route
					path='users'
					element={
						<ProtectedElement
							enabled={() =>
								level === userLevel[UserEnum.SuperUser] ||
								level === userLevel[UserEnum.Admin]
							}
						>
							<Users />
						</ProtectedElement>
					}
				/>
				<Route
					path='user'
					element={
						<ProtectedElement
							enabled={() =>
								level === userLevel[UserEnum.SuperUser] ||
								level === userLevel[UserEnum.Admin]
							}
						>
							<User />
						</ProtectedElement>
					}
				/>
				<Route
					path='debug'
					element={
						<ProtectedElement
							enabled={() =>
								level === userLevel[UserEnum.SuperUser] ||
								level === userLevel[UserEnum.Admin]
							}
						>
							<DebugPage />
						</ProtectedElement>
					}
				/>
				<Route
					path='test'
					element={
						<ProtectedElement
							enabled={() =>
								level === userLevel[UserEnum.SuperUser] ||
								level === userLevel[UserEnum.Admin]
							}
						>
							<TestPage />
						</ProtectedElement>
					}
				/>
				<Route
					path='calendars'
					element={
						<ProtectedElement
							enabled={() =>
								level === userLevel[UserEnum.SuperUser] ||
								level === userLevel[UserEnum.Admin]
							}
						>
							<Calendars />
						</ProtectedElement>
					}
				/>
				<Route
					path='calendar'
					element={
						<ProtectedElement
							enabled={() =>
								level === userLevel[UserEnum.SuperUser] ||
								level === userLevel[UserEnum.Admin]
							}
						>
							<Calendar />
						</ProtectedElement>
					}
				/>
				<Route
					path='groups'
					element={
						<ProtectedElement
							enabled={() =>
								level === userLevel[UserEnum.SuperUser] ||
								level === userLevel[UserEnum.Admin]
							}
						>
							<Groups />
						</ProtectedElement>
					}
				/>
				<Route
					path='group'
					element={
						<ProtectedElement
							enabled={() =>
								level === userLevel[UserEnum.SuperUser] ||
								level === userLevel[UserEnum.Admin]
							}
						>
							<Group />
						</ProtectedElement>
					}
				/>
			</Routes>
		</Box>
	);
}
