import DateObject from "react-date-object";
import { ICalendarRange } from "../interface/Entities";
import { RangeType } from "../interface/Enums";

export function CheckTime(range?: ICalendarRange): boolean {
	console.log("Qua");
	if (
		!range ||
		range?.description === undefined ||
		range?.description.length === 0 ||
		range?.calendarOperation === undefined ||
		(range?.doorsOperation === undefined && (range?.doors?.length ?? 0) > 0)
	)
		return false;
	switch (range?.rangeType) {
		case RangeType.Recursive:
			return CheckRecursiveRange(range);
		case RangeType.Single:
			return CheckSingleRange(range);
	}
	return false;
}

export function CheckSingleRange(range: ICalendarRange): boolean {
	if (!range.dateRange) return false;

	if (range.timeRange) {
		var timeRange = range.timeRange as DateObject[];
		var fromTime = timeRange[0];
		var toTime = timeRange[1];
		if (toTime && fromTime > toTime) return false;
	}

	return true;
}

export function CheckRecursiveRange(range: ICalendarRange): boolean {
	if (!range.daysOfWeek || range.daysOfWeek?.length == 0) return false;
	if (range.timeRange) {
		var timeRange = range.timeRange as DateObject[];
		var fromTime = timeRange[0];
		var toTime = timeRange[1];
		if (toTime && fromTime > toTime) return false;
	}

	if (range.startEndDateRange) {
		var dateRange = range.startEndDateRange as DateObject[];
		var fromDate = dateRange[0];
		var toDate = dateRange[1];
		if (toDate && fromDate > toDate) return false;
	}

	return true;
}
