import { useCallback, useEffect, useState } from "react";
import {
	AttributeType,
	IKeyCloakGroup,
	useKeycloak,
} from "react-keycloak-manager";
import GatesTable from "../components/ZonesTable";
import { IGate } from "../interface/Entities";
import { VppZoneName } from "../interface/Constants";
import { getGates } from "../libraries/RestManager";

interface IUserGatesProps {
	group: IKeyCloakGroup | null;
	onChange: () => void;
}

export default function UserGates({ group: group, onChange }: IUserGatesProps) {
	const keyCloak = useKeycloak();
	const { isAuthenticated } = keyCloak;
	const [gates, setGates] = useState<IGate[]>([]);

	const fetchGates = useCallback(async () => {
		if (isAuthenticated && group && group.attributes) {
			var gateList = (await getGates(keyCloak)) ?? [];

			assignGateLevel(gateList, group?.attributes);

			setGates(gateList ?? []);
		} else setGates([]);
	}, [isAuthenticated, group]);

	useEffect(() => {
		fetchGates();
	}, [fetchGates, group]);

	function onEnableChange(gate: IGate): void {
		var newArr = [...gates];
		var z = newArr.find((z) => z.name === gate.name && z.zone === gate.zone);
		if (z) {
			z.enabled = gate.enabled;
			setGates(newArr);
		}
		saveGateLevel(gates, group?.attributes);
		onChange();
	}

	return <GatesTable gates={gates} onEnabledChange={onEnableChange} />;
}

function assignGateLevel(
	zones: IGate[],
	attributes: AttributeType | undefined,
) {
	var localName: string[] =
		attributes && attributes[VppZoneName] ? attributes[VppZoneName] : [];

	zones.forEach((z) => {
		var index = localName.findIndex((k) => {
			var s = splitKey(k);
			return s.name == z.name && s.zone == z.zone;
		});
		z.enabled = index !== -1 ? true : false;
	});
}

function saveGateLevel(zones: IGate[], attributes: AttributeType | undefined) {
	var localName: string[] = [];
	zones.forEach((z) => {
		if (z.enabled) {
			localName.push(combineKey(z));
		}
	});

	attributes![VppZoneName] = localName;
}

function splitKey(key: string): IGate {
	var splitted = key.split("-");
	return {
		zone: splitted[0],
		name: splitted[1],
		friendlyName: "",
		enabled: false,
		active: false,
	};
}

function combineKey(zone: IGate): string {
	return zone.zone + "-" + zone.name;
}
