import CloseIcon from "@mui/icons-material/Close";
import {
	Alert,
	AlertColor,
	Box,
	Button,
	IconButton,
	Snackbar,
	Tooltip,
} from "@mui/material";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "react-keycloak-manager";
import GatesTable from "../components/GatesTable";
import { ExtendedProfile, IGate } from "../interface/Entities";
import { UserEnum, userLevel } from "../interface/Enums";
import {
	getGatesEnabled,
	openDoor,
	refreshGates,
} from "../libraries/RestManager";

export default function GatesPage() {
	const { authenticatedUser, isAuthenticated, post } = useKeycloak();
	const keyCloak = useKeycloak();

	const [doors, setDoors] = useState<IGate[]>([]);
	const [openSnack, setOpenSnack] = useState(false);
	const [snackMessage, setSnackMessage] = useState("");
	const [severity, setSeverity] = useState<AlertColor>("success");
	const [refreshDevices, setRefreshDevices] = useState(0);
	const [isAdmin, setIsAdmin] = useState<boolean>(false);
	const { t } = useTranslation();

	const fetchGates = useCallback(async () => {
		if (isAuthenticated) {
			var gates = await getGatesEnabled(keyCloak);
			setDoors(gates ?? []);
		} else setDoors([]);
	}, [isAuthenticated, refreshDevices]);

	const openGate = async (zone: string, name: string, description: string) => {
		var result = await openDoor(keyCloak, {
			zone: zone,
			name: name,
		});
		setOpenSnack(true);
		if (result == "OK") {
			setSnackMessage(`${description} ${t("opened")}`);
			setSeverity("success");
		} else {
			setSnackMessage(`${description} ${result}`);
			setSeverity("error");
		}
		console.log(result);
	};

	const handleClose = (
		event: React.SyntheticEvent | Event,
		reason?: string,
	) => {
		setOpenSnack(false);
	};

	const action = (
		<Fragment>
			<Button color='secondary' size='small' onClick={handleClose}>
				UNDO
			</Button>
			<IconButton
				size='small'
				aria-label='close'
				color='inherit'
				onClick={handleClose}
			>
				<CloseIcon fontSize='small' />
			</IconButton>
		</Fragment>
	);

	const refreshDevice = async (e: any) => {
		await refreshGates(keyCloak);
		setRefreshDevices(Date.now());
	};

	useEffect(() => {
		var extUser = authenticatedUser?.profile as ExtendedProfile;
		setIsAdmin(
			extUser?.VppUserLevel === userLevel[UserEnum.Admin] ||
				extUser?.VppUserLevel === userLevel[UserEnum.SuperUser],
		);
	}, [isAuthenticated]);

	useEffect(() => {
		fetchGates();
	}, [fetchGates]);

	return (
		<>
			<Snackbar
				open={openSnack}
				autoHideDuration={3000}
				onClose={handleClose}
				message={snackMessage}
				// action={action}
			>
				<Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
					{snackMessage}
				</Alert>
			</Snackbar>
			<GatesTable
				gates={doors}
				onOpenGate={async (zone, name, description) => {
					openGate(zone, name, description);
				}}
			/>
			{isAdmin && (
				<Box display='flex' justifyContent='flex-end'>
					<Tooltip title={t("refreshText")}>
						<Button variant='contained' onClick={refreshDevice}>
							{t("refresh")}
						</Button>
					</Tooltip>
				</Box>
			)}
		</>
	);
}
