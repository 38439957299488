import {
	Box,
	Checkbox,
	FormControlLabel,
	Typography,
	useTheme,
} from "@mui/material";
import { weekdays } from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker, { DateObject, Value } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

interface IRecursiveCalendarProp {
	daysOfWeek: string | undefined;
	timeRange: Value | undefined;
	dateRange: Value | undefined;
	onDaysOfWeekChange?: (value: string) => void;
	onDateChange?: (value: Value) => void;
	onTimeChange?: (value: Value) => void;
}

export default function RecursiveCalendar({
	daysOfWeek,
	timeRange,
	dateRange,
	onTimeChange,
	onDateChange,
	onDaysOfWeekChange,
}: IRecursiveCalendarProp) {
	const [timeFrom, setTimeFrom] = useState<Value>();
	const [timeTo, setTimeTo] = useState<Value>();
	const [dateFrom, setDateFrom] = useState<Value>();
	const [dateTo, setDateTo] = useState<Value>();
	const { t } = useTranslation();

	const weekDaysName = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

	const [weekArray, setWeekArray] = useState<boolean[]>([
		false,
		false,
		false,
		false,
		false,
		false,
		false,
	]);
	const months = [
		"jan",
		"feb",
		"mar",
		"apr",
		"may",
		"jun",
		"jul",
		"aug",
		"sep",
		"oct",
		"nov",
		"dec",
	].map((v) => t(v));

	const weekDays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"].map((d) =>
		t(d),
	);

	const { palette } = useTheme();
	const [bgCalendar, setBgCalendar] = useState("bg-white");
	const [pickerColor, setPickerColor] = useState("black");
	const [pickerBgColor, setBgPickerColor] = useState("white");

	useEffect(() => {
		setBgCalendar(palette.mode === "dark" ? "bg-dark" : "");
		setPickerColor(
			palette.mode === "dark" ? palette.common.white : palette.common.black,
		);
		setBgPickerColor(
			palette.mode === "dark" ? palette.common.black : palette.common.white,
		);
	}, [palette.mode]);

	useEffect(() => {
		if (timeRange) {
			var t = timeRange as DateObject[];
			if (t.length > 1) {
				setTimeFrom(t[0]);
				setTimeTo(t[1]);
			}
		}
		if (daysOfWeek) {
			var wd = { ...weekArray };
			weekDaysName.forEach((v, i) => {
				wd[i] = daysOfWeek.indexOf(v) >= 0;
			});
			setWeekArray(wd);
		}
		if (dateRange) {
			var t = dateRange as DateObject[];
			if (t.length > 1) {
				setDateFrom(t[0]);
				setDateTo(t[1]);
			}
		}
	}, []);

	function changeCheckBox(index: number, checked: boolean): boolean[] {
		var weekDays = { ...weekArray };
		weekDays[index] = checked;
		setWeekArray(weekDays);
		return weekDays;
	}

	return (
		<Box>
			<Box>
				{weekDaysName.map((w, i) => {
					return (
						<FormControlLabel
							key={i}
							label={<Typography variant='body2'>{t(w)}</Typography>}
							control={
								<Checkbox
									size='small'
									checked={weekArray[i]}
									onChange={(v) => {
										createArray(i, v);
									}}
								/>
							}
						/>
					);
				})}
			</Box>
			<Typography
				variant='subtitle1'
				align='center'
				fontWeight={"bold"}
				margin={1}
			>
				{t("hours")}
			</Typography>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					marginBottom: 2,
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						minWidth: 150,
						alignItems: "center",
					}}
				>
					<Box sx={{ minWidth: 50 }}>
						<Typography variant='body2'>{t("from")}</Typography>
					</Box>
					<DatePicker
						style={{
							maxWidth: 50,
							color: pickerColor,
							backgroundColor: pickerBgColor,
						}}
						className={bgCalendar}
						disableDayPicker
						format='HH:mm'
						value={timeFrom}
						onChange={(v) => {
							setTimeFrom(v);
							combineTime(v, timeTo);
						}}
						plugins={[<TimePicker hideSeconds />]}
					/>
				</Box>
				<Box sx={{ minWidth: 50, marginLeft: 5 }}>
					<Typography variant='body2'>{t("to")}</Typography>
				</Box>
				<DatePicker
					style={{
						maxWidth: 50,
						color: pickerColor,
						backgroundColor: pickerBgColor,
					}}
					className={bgCalendar}
					disableDayPicker
					format='HH:mm'
					value={timeTo}
					onChange={(v) => {
						setTimeTo(v);
						combineTime(timeFrom, v);
					}}
					plugins={[<TimePicker hideSeconds />]}
				/>
			</Box>
			<Typography
				variant='subtitle1'
				align='center'
				fontWeight={"bold"}
				margin={1}
			>
				{t("period")}
			</Typography>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						minWidth: 150,
						alignItems: "center",
					}}
				>
					<Box sx={{ minWidth: 50 }}>
						<Typography variant='body2'>{t("fromDt")}</Typography>
					</Box>
					<DatePicker
						style={{
							maxWidth: 70,
							color: pickerColor,
							backgroundColor: pickerBgColor,
						}}
						className={bgCalendar}
						format={t("calendarFormat") ?? "DD/MM/YY"}
						value={dateFrom}
						months={months}
						weekDays={weekDays}
						weekStartDayIndex={1}
						onChange={(v) => {
							setDateFrom(v);
							combineDate(v, dateTo);
						}}
					/>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						minWidth: 150,
						alignItems: "center",
					}}
				>
					<Box sx={{ minWidth: 50, marginLeft: 5 }}>
						<Typography variant='body2'>{t("toDt")}</Typography>
					</Box>
					<DatePicker
						style={{
							maxWidth: 70,
							color: pickerColor,
							backgroundColor: pickerBgColor,
						}}
						className={bgCalendar}
						weekStartDayIndex={1}
						format={t("calendarFormat") ?? "DD/MM/YY"}
						value={dateTo}
						months={months}
						weekDays={weekDays}
						onChange={(v) => {
							setDateTo(v);
							combineDate(dateFrom, v);
						}}
					/>
				</Box>
			</Box>
		</Box>
	);

	function createArray(i: number, v: any) {
		var wArray = changeCheckBox(i, v.target.checked);
		if (onDaysOfWeekChange) {
			var daysOfWeek = weekDaysName.reduce(
				(acc, curr, index) =>
					wArray[index] ? acc + (acc.length > 0 ? "," : "") + curr : acc,
				"",
			);
			onDaysOfWeekChange(daysOfWeek);
		}
	}

	function combineTime(from: Value | undefined, to: Value | undefined) {
		if (onTimeChange) {
			var range: Value = [from as DateObject, to as DateObject];
			onTimeChange(range);
		}
	}

	function combineDate(from: Value | undefined, to: Value | undefined) {
		if (onDateChange) {
			var range: Value = [from as DateObject, to as DateObject];
			onDateChange(range);
		}
	}
}
