import {
	Box,
	Button,
	IconButton,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import DateObject from "react-date-object";
import { useTranslation } from "react-i18next";
import DatePicker, { Calendar, Value } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import ClearIcon from "@mui/icons-material/Clear";

interface ISingleCalendarProp {
	dateRange: Value | undefined;
	timeRange: Value | undefined;
	onDateChange?: (value?: Value) => void;
	onTimeChange?: (value: Value) => void;
}

export default function SingleCalendar({
	dateRange,
	timeRange,
	onDateChange,
	onTimeChange,
}: ISingleCalendarProp) {
	const [val, setVal] = useState<Value | undefined>(dateRange);
	const [timeFrom, setTimeFrom] = useState<Value>();
	const [timeTo, setTimeTo] = useState<Value>();
	const { t } = useTranslation();
	const months = [
		"jan",
		"feb",
		"mar",
		"apr",
		"may",
		"jun",
		"jul",
		"aug",
		"sep",
		"oct",
		"nov",
		"dec",
	].map((v) => t(v));

	const weekDays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"].map((d) =>
		t(d),
	);

	const { palette } = useTheme();
	const [bgCalendar, setBgCalendar] = useState("bg-white");
	const [pickerColor, setPickerColor] = useState("black");
	const [pickerBgColor, setBgPickerColor] = useState("white");

	useEffect(() => {
		setBgCalendar(palette.mode === "dark" ? "bg-dark" : "");
		setPickerColor(
			palette.mode === "dark" ? palette.common.white : palette.common.black,
		);
		setBgPickerColor(
			palette.mode === "dark" ? palette.common.black : palette.common.white,
		);
	}, [palette.mode]);

	useEffect(() => {
		if (timeRange) {
			var tr = timeRange as DateObject[];
			if (tr.length > 1) {
				setTimeFrom(tr[0]);
				setTimeTo(tr[1]);
			}
		}
	}, []);

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				flexDirection: "column",
			}}
		>
			<Typography
				variant='subtitle1'
				align='center'
				fontWeight={"bold"}
				margin={1}
			>
				{t("periods")}
			</Typography>
			<Box sx={{ display: "flex" }}>
				<Calendar
					className={bgCalendar}
					multiple
					range
					months={months}
					weekDays={weekDays}
					value={val}
					weekStartDayIndex={1}
					onChange={(v) => {
						setVal(v);
						if (v && onDateChange) onDateChange(v);
					}}
				/>
				<Box>
					<Tooltip title={t("clear")}>
						<IconButton
							color='primary'
							onClick={() => {
								setVal(undefined);
								if (onDateChange) onDateChange(undefined);
							}}
						>
							<ClearIcon />
						</IconButton>
					</Tooltip>
				</Box>
			</Box>
			<Box sx={{ marginTop: 1 }}>
				<Typography
					variant='subtitle1'
					align='center'
					fontWeight={"bold"}
					margin={1}
				>
					Orario
				</Typography>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						minWidth: 150,
					}}
				>
					<Box sx={{ minWidth: 50 }}>
						<Typography>{t("from")}</Typography>
					</Box>
					<DatePicker
						className={bgCalendar}
						disableDayPicker
						style={{
							maxWidth: 50,
							color: pickerColor,
							backgroundColor: pickerBgColor,
						}}
						format='HH:mm'
						value={timeFrom}
						onChange={(v) => {
							setTimeFrom(v);
							combineTime(v, timeTo);
						}}
						plugins={[<TimePicker hideSeconds />]}
					/>
					<Box sx={{ minWidth: 50, marginLeft: 5 }}>
						<Typography>{t("to")}</Typography>
					</Box>

					<DatePicker
						className={bgCalendar}
						disableDayPicker
						style={{
							maxWidth: 50,
							color: pickerColor,
							backgroundColor: pickerBgColor,
						}}
						format='HH:mm'
						value={timeTo}
						onChange={(v) => {
							setTimeTo(v);
							combineTime(timeFrom, v);
						}}
						plugins={[<TimePicker hideSeconds />]}
					/>
				</Box>
			</Box>
		</Box>
	);

	function combineTime(from: Value | undefined, to: Value | undefined) {
		if (from && to && onTimeChange) {
			var range: Value = [from as DateObject, to as DateObject];
			onTimeChange(range);
		}
	}
}
