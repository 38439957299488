import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
	Checkbox,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Tooltip,
	styled,
	tableCellClasses,
	useTheme,
} from "@mui/material";
import { ChangeEvent, MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { IKeyCloakUser, useKeycloak } from "react-keycloak-manager";
import { Link } from "react-router-dom";
import { ConfirmDialog } from "react-vevy-library";

export default function UserTable({
	users,
	onRefreshTable,
}: {
	users: IKeyCloakUser[];
	onRefreshTable: () => void;
}): JSX.Element {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const { t } = useTranslation();
	const theme = useTheme();
	const [currentUser, setCurrentUser] = useState("");
	const [userId, setUSerId] = useState("");
	const [openDialog, setOpenDialog] = useState(false);
	const keyCloak = useKeycloak();

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.common.white,
			fontSize: 16,
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 16,
		},
	}));

	interface TablePaginationActionsProps {
		count: number;
		page: number;
		rowsPerPage: number;
		onPageChange: (
			event: MouseEvent<HTMLButtonElement>,
			newPage: number,
		) => void;
	}

	function TablePaginationActions(props: TablePaginationActionsProps) {
		const { count, page, rowsPerPage, onPageChange } = props;

		const handleFirstPageButtonClick = (
			event: MouseEvent<HTMLButtonElement>,
		) => {
			onPageChange(event, 0);
		};

		const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
			onPageChange(event, page - 1);
		};

		const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
			onPageChange(event, page + 1);
		};

		const handleLastPageButtonClick = (
			event: MouseEvent<HTMLButtonElement>,
		) => {
			onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
		};
	}

	const handleChangePage = (
		event: MouseEvent<HTMLButtonElement> | null,
		newPage: number,
	) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const ondeleteUser = (
		id: string,
		lastName: string,
		firstName: string,
	): void => {
		setCurrentUser(`${firstName} ${lastName}`);
		setUSerId(id);
		setOpenDialog(true);
	};

	function onCloseDialog(): void {
		setOpenDialog(false);
	}

	async function onConfirmDeleteUser(): Promise<void> {
		if (userId !== "") {
			await keyCloak.deleteUser(userId);
			onRefreshTable();
		}
		onCloseDialog();
	}

	return (
		<>
			<TableContainer
				sx={{ marginTop: "1em", maxHeight: "80vh", minHeight: "60vh" }}
			>
				<Table stickyHeader aria-label='sticky table' size='small'>
					<TableHead>
						<TableRow>
							<StyledTableCell
								style={{
									background: theme.palette.primary.dark,
									color: theme.palette.common.white,
								}}
							>
								{t("username")}
							</StyledTableCell>
							<StyledTableCell>{t("name")}</StyledTableCell>
							<StyledTableCell
								sx={{ display: { xs: "none", md: "table-cell" } }}
							>
								{t("email")}
							</StyledTableCell>
							<StyledTableCell
								sx={{ display: { xs: "none", md: "table-cell" } }}
							>
								{t("enabled")}
							</StyledTableCell>
							<StyledTableCell>{t("actions")}</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{users
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row) => {
								return (
									<TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
										<TableCell>{row.username}</TableCell>
										<TableCell>{`${row.firstName} ${row.lastName}`}</TableCell>
										<TableCell
											sx={{ display: { xs: "none", md: "table-cell" } }}
										>
											{row.email}
										</TableCell>
										<TableCell
											sx={{ display: { xs: "none", md: "table-cell" } }}
										>
											<Checkbox
												size='small'
												color='primary'
												checked={row.enabled}
											/>
										</TableCell>
										<TableCell>
											<Tooltip title={t("edit")}>
												<IconButton
													size='small'
													color='primary'
													component={Link}
													to={`/user?id=${row.id}`}
												>
													<EditIcon />
												</IconButton>
											</Tooltip>
											<Tooltip title={t("delete")}>
												<IconButton
													size='small'
													color='primary'
													aria-label='delete'
													onClick={(e) =>
														ondeleteUser(row.id, row.lastName, row.firstName)
													}
												>
													<DeleteIcon />
												</IconButton>
											</Tooltip>
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[10, 25, 50]}
								colSpan={6}
								count={users.length}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: {
										"aria-label": t("rowsPerPage") ?? "",
									},
									native: true,
								}}
								labelRowsPerPage={t("rowsPerPage") ?? ""}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
			<ConfirmDialog
				open={openDialog}
				title={t("deleteTitle")}
				content={`${t("deleteMessage")} ${currentUser}`}
				actionOk={onConfirmDeleteUser}
				actionCancel={onCloseDialog}
			/>
		</>
	);
}
