import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { JSONTree } from "react-json-tree";
import { useAuth } from "react-oidc-context";
import { getServerTime, getUtcServerTime } from "../libraries/RestManager";
import { useKeycloak } from "react-keycloak-manager";

export default function DebugPage() {
	const keyCloak = useKeycloak();
	const auth = useAuth();
	const { palette } = useTheme();
	const [invert, setInvert] = useState<boolean>(true);
	const [serverTime, setServerTime] = useState<Date>(new Date());
	const [utcServerTime, setUtcServerTime] = useState<Date>(new Date());

	useEffect(() => {
		setInvert(palette.mode === "light");
	}, [palette.mode]);

	function parseJwt(token: string | undefined) {
		if (!token) return "";
		var base64Url = token.split(".")[1];
		var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
		var jsonPayload = decodeURIComponent(
			window
				.atob(base64)
				.split("")
				.map(function (c) {
					return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join(""),
		);

		return JSON.parse(jsonPayload);
	}

	const theme = {
		scheme: "monokai",
		author: "wimer hazenberg (http://www.monokai.nl)",
		base00: "#272822",
		base01: "#383830",
		base02: "#49483e",
		base03: "#75715e",
		base04: "#a59f85",
		base05: "#f8f8f2",
		base06: "#f5f4f1",
		base07: "#f9f8f5",
		base08: "#f92672",
		base09: "#fd971f",
		base0A: "#f4bf75",
		base0B: "#a6e22e",
		base0C: "#a1efe4",
		base0D: "#66d9ef",
		base0E: "#ae81ff",
		base0F: "#cc6633",
	};

	let idToken = parseJwt(auth.user?.id_token);
	let accessToken = parseJwt(auth.user?.access_token);
	let refreshToken = parseJwt(auth.user?.refresh_token);

	const fetchServerTime = useCallback(async () => {
		var serverTime = await getServerTime(keyCloak);
		if (serverTime) setServerTime(serverTime);
	}, []);

	useEffect(() => {
		fetchServerTime();
	}, [fetchServerTime]);

	return (
		<Box>
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				<Typography>{`Local Utc Time ${new Date().toUTCString()}`}</Typography>
				<Typography>{`Local Time ${new Date().toLocaleString()}`}</Typography>
				<Typography>{`Server Utc Time ${utcServerTime.toLocaleString()}`}</Typography>
				<Typography>{`Server Time ${serverTime.toLocaleString()}`}</Typography>
			</Box>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell> ID TOKEN</TableCell>
						<TableCell> ACCESS TOKEN</TableCell>
						<TableCell> REFRESH TOKEN</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell>
							<JSONTree
								labelRenderer={([key]) => (
									<strong>{key === "root" ? "ID Token" : key}</strong>
								)}
								data={idToken}
								theme={theme}
								invertTheme={invert}
							/>
						</TableCell>
						<TableCell>
							<JSONTree
								labelRenderer={([key]) => (
									<strong>{key === "root" ? "Access Token" : key}</strong>
								)}
								data={accessToken}
								theme={theme}
								invertTheme={invert}
							/>
						</TableCell>
						<TableCell>
							<JSONTree
								labelRenderer={([key]) => (
									<strong>{key === "root" ? "Refresh Token" : key}</strong>
								)}
								data={refreshToken}
								theme={theme}
								invertTheme={invert}
							/>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</Box>
	);
}
