import { Box, Button, useTheme } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import DateObject from "react-date-object";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "react-keycloak-manager";
import { Calendar } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/analog_time_picker";
import { useSearchParams } from "react-router-dom";
import GatesEnableTable from "../components/GatesEnableTable";
import {
	IDayToCheck,
	IDaysToCheck,
	IDoorStatus,
	IGate,
	IStateDay,
} from "../interface/Entities";
import { checkPeriod, getGates } from "../libraries/RestManager";

export default function CalendarTest() {
	const { t } = useTranslation();

	const months = [
		"jan",
		"feb",
		"mar",
		"apr",
		"may",
		"jun",
		"jul",
		"aug",
		"sep",
		"oct",
		"nov",
		"dec",
	].map((v) => t(v));

	const weekDays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"].map((d) =>
		t(d),
	);

	const [today, setToday] = useState<DateObject>(new DateObject());
	const [mapEnable, setMapEnable] = useState(new Map<string, IStateDay>());
	const [doorsEnabled, setDoorsEnabled] = useState<IGate[]>([]);
	const [todayDoors, setTodayDoors] = useState<IDoorStatus[]>([]);
	const [gates, setGates] = useState<IGate[] | null>([]);
	const [rangeDays, setRangeDays] = useState<IDayToCheck[]>([]);

	const keyCloak = useKeycloak();
	const { palette } = useTheme();
	const [bgCalendar, setBgCalendar] = useState("bg-white");
	const [searchParams] = useSearchParams();
	const id = searchParams.get("id");

	const fetchDoors = useCallback(async () => {
		setGates(await getGates(keyCloak));
	}, []);

	useEffect(() => {
		fetchDoors();
	}, [fetchDoors]);

	useEffect(() => {
		var bgColor = palette.mode === "dark" ? "bg-dark" : "";
		setBgCalendar(bgColor);
	}, [palette.mode]);

	useEffect(() => {
		var currentGates: IGate[] = [];
		gates?.forEach((g) => {
			const key = `${g.zone}-${g.name}`;
			var door = todayDoors.find((t) => t.key == key);
			if (door) currentGates.push({ ...g, active: door.active });
		});
		setDoorsEnabled([...currentGates]);
	}, [todayDoors]);

	useEffect(() => {
		const todayString = today.format("YYYYMMDD");
		var result = rangeDays.find(
			(r) => new DateObject(r.day).format("YYYYMMDD") == todayString,
		);
		if (result) setTodayDoors(result.doors ?? []);
	}, [today]);

	async function CheckCalendar() {
		var daysToCheck: IDaysToCheck = {
			today: today,
			days: [],
		};

		var a: IDoorStatus = { key: "a", active: true };
		var doorList = gates?.map<IDoorStatus>((d) => ({
			key: `${d.zone}-${d.name}`,
			active: true,
		}));
		mapEnable.forEach((v, k) => {
			var dayToCheck: IDayToCheck = {
				day: v.day.toDate(),
				disabled: false,
				doors: doorList,
			};
			if (!v.checked) daysToCheck.days.push(dayToCheck);
		});

		daysToCheck.calendar = +(id ?? 0);

		const todayString = today.format("YYYYMMDD");
		var result = await checkPeriod(keyCloak, daysToCheck);
		setRangeDays(result ?? []);
		result?.forEach((r) => {
			var key = new DateObject(r.day).format("YYYYMMDD");
			var day = mapEnable.get(key);
			if (day) {
				mapEnable.set(key, {
					...day,
					checked: true,
					disabled: r.disabled,
				});
			}
			if (key == todayString) {
				setTodayDoors(r.doors ?? []);
			}
		});
		setToday(new DateObject(today));
	}

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				margin: 2,
			}}
		>
			<Calendar
				className={bgCalendar}
				numberOfMonths={3}
				value={today}
				plugins={[<TimePicker hideSeconds position='right' format='HH:mm' />]}
				onChange={(v) => {
					mapEnable.forEach((v, k) => {
						setMapEnable(
							mapEnable.set(k, {
								...v,
								checked: false,
							}),
						);
					});
					setToday(v as DateObject);
				}}
				months={months}
				weekDays={weekDays}
				mapDays={({ date }) => {
					var key = date.format("YYYYMMDD");
					if (mapEnable.has(key)) {
						var state = mapEnable.get(key);
						return {
							style: { color: state?.disabled ? "red" : undefined },
							disabled: false,
						};
					} else {
						setMapEnable(
							mapEnable.set(key, {
								day: date,
								disabled: false,
								checked: false,
							}),
						);
						return { disabled: false };
					}
				}}
				weekStartDayIndex={1}
			/>
			<Button
				sx={{ marginTop: 2 }}
				variant='contained'
				onClick={async () => {
					await CheckCalendar();
				}}
			>
				{t("check")}
			</Button>
			<GatesEnableTable gates={doorsEnabled} />
		</Box>
	);
}
