import { Box, SvgIcon, Tab, Tabs } from "@mui/material";
import {
	Dispatch,
	ReactElement,
	ReactNode,
	SetStateAction,
	SyntheticEvent,
	useState,
} from "react";

interface TabPanelProps {
	children?: ReactNode;
	index: number;
	value: number;
	panelKey: string;
}

interface ITabberProps {
	panels?: ITabPanel[];
	curPanel: number;
	setPanel: Dispatch<SetStateAction<number>>;
}

export interface ITabPanel {
	title: string;
	panel: ReactNode;
	icon?: ReactElement;
}

export default function Tabber({ panels }: { panels?: ITabPanel[] }) {
	const [value, setValue] = useState(0);
	return (
		<Box
			sx={{
				marginTop: 1,
				minHeight: "80vh",
			}}
		>
			<HorizzontalTabber
				panels={panels}
				curPanel={value}
				setPanel={setValue}
			></HorizzontalTabber>
			{/* <VerticalTabber
				panels={panels}
				curPanel={value}
				setPanel={setValue}
			></VerticalTabber> */}
		</Box>
	);
}

export function HorizzontalTabber(props: ITabberProps) {
	const { panels, curPanel, setPanel } = props;

	const handleChange = (event: SyntheticEvent, newValue: number) => {
		setPanel(newValue);
	};
	return (
		<Box
			sx={{
				flexGrow: 1,
				bgcolor: "background.paper",
				// display: { xs: "block", lg: "none" },
			}}
		>
			<Tabs
				value={curPanel}
				onChange={handleChange}
				indicatorColor='primary'
				textColor='inherit'
				variant='scrollable'
				aria-label='full width tabs example'
				sx={{ borderRight: 1, borderColor: "divider" }}
			>
				{panels?.map((p, i) => {
					return (
						<Tab
							iconPosition='start'
							icon={p.icon}
							label={p.title}
							key={`tabh-${i}`}
						/>
					);
				})}
			</Tabs>
			<Box sx={{ margin: 1 }}>
				<ShowPanels curPanel={curPanel} panels={panels} panelKey='horizz' />
			</Box>
		</Box>
	);
}

export function VerticalTabber(props: ITabberProps) {
	const { panels, curPanel, setPanel } = props;

	const handleChange = (event: SyntheticEvent, newValue: number) => {
		setPanel(newValue);
	};
	return (
		<Box
			sx={{
				bgcolor: "background.paper",
				display: "flex",
				flexGrow: 1,
			}}
		>
			<Tabs
				value={curPanel}
				orientation='vertical'
				variant='scrollable'
				onChange={handleChange}
				indicatorColor='primary'
				textColor='inherit'
				aria-label='full width tabs example'
				sx={{ borderRight: 1, borderColor: "divider", marginRight: 1 }}
			>
				{panels?.map((p, i) => {
					return <Tab label={p.title} key={`tabv-${i}`} icon={p.icon} />;
				})}
			</Tabs>
			<Box sx={{ flexGrow: 1 }}>
				<ShowPanels curPanel={curPanel} panels={panels} panelKey='vertical' />
			</Box>
		</Box>
	);
}

function ShowPanels({
	curPanel,
	panels,
	panelKey,
}: {
	curPanel: number;
	panels: ITabPanel[] | undefined;
	panelKey: string;
}) {
	return (
		<>
			{panels?.map((p, i) => {
				return (
					<TabPanel
						key={`tb${panelKey}${i}`}
						value={curPanel}
						index={i}
						panelKey={panelKey}
					>
						{p.panel}
					</TabPanel>
				);
			})}
		</>
	);
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, panelKey, ...other } = props;

	return (
		<div
			key={`${panelKey}-${index}`}
			role='tabpanel'
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}
		>
			{value === index && <>{children}</>}
		</div>
	);
}
