import {
	Checkbox,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	styled,
	tableCellClasses,
	useTheme,
} from "@mui/material";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { IGate } from "../interface/Entities";

interface zoneTableProps {
	gates: IGate[];
	onEnabledChange: (gate: IGate) => void;
}

export default function GatesTable({
	gates,
	onEnabledChange,
}: zoneTableProps): JSX.Element {
	const { t } = useTranslation();
	const theme = useTheme();

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.common.white,
			fontSize: 16,
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 16,
		},
	}));

	const onCheckboxChanged = (e: ChangeEvent<HTMLInputElement>, row: IGate) => {
		row.enabled = e.target.checked;
		onEnabledChange(row);
	};

	return (
		<TableContainer
			sx={{ minHeight: "75vh", maxHeight: "75vh", overflowY: "false" }}
		>
			<Table stickyHeader aria-label='sticky table' size='small'>
				<TableHead>
					<TableRow>
						<StyledTableCell
							style={{
								background: theme.palette.primary.dark,
								color: theme.palette.common.white,
							}}
						>
							{t("gate")}
						</StyledTableCell>
						<StyledTableCell>{t("name")}</StyledTableCell>
						<StyledTableCell>{t("description")}</StyledTableCell>
						<StyledTableCell>{t("enabled")}</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{gates.map((row) => {
						return (
							<TableRow
								hover
								role='checkbox'
								tabIndex={-1}
								key={row.name + row.zone}
							>
								<TableCell>{row.zone}</TableCell>
								<TableCell>{row.name}</TableCell>
								<TableCell>{row.friendlyName}</TableCell>
								<TableCell>
									<Checkbox
										checked={row.enabled}
										onChange={(e) => onCheckboxChanged(e, row)}
									/>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
