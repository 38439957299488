import { Box, Button, IconButton, TextField, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import UndoIcon from "@mui/icons-material/Undo";
import { useTranslation } from "react-i18next";

interface TextEditSaveProps {
	text?: string;
	onConfirm?: (txt: string) => void;
}
export default function TextEditSave({ text, onConfirm }: TextEditSaveProps) {
	const [txt, setTxt] = useState<string>("");
	const [inEdit, setInEdit] = useState<boolean>(false);
	const { t } = useTranslation();

	useEffect(() => {
		setTxt(text || "");
	}, [text]);

	return (
		<Box
			sx={{
				margin: 1,
				display: "flex",
			}}
		>
			<TextField
				variant='standard'
				value={txt}
				onChange={(v) => setTxt(v.target.value)}
				disabled={!inEdit}
			/>
			{inEdit ? (
				<>
					<Tooltip title={t("delete")}>
						<IconButton
							color='primary'
							aria-label='save'
							onClick={() => {
								setInEdit(false);
								onConfirm && onConfirm(txt);
							}}
						>
							<SaveIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title={t("delete")}>
						<IconButton
							color='primary'
							aria-label='undo'
							onClick={() => setInEdit(false)}
						>
							<UndoIcon />
						</IconButton>
					</Tooltip>
				</>
			) : (
				<Tooltip title={t("delete")}>
					<IconButton
						color='primary'
						aria-label='edit'
						onClick={() => setInEdit(true)}
					>
						<EditIcon />
					</IconButton>
				</Tooltip>
			)}
		</Box>
	);
}
