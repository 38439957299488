import i18next, { TFunction } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import transEn from "../translations/english/translation.json";
import transIt from "../translations/italian/translation.json";

export type TranslationFunction = TFunction<
	"translation",
	"undefined",
	"translation"
>;

const resources = {
	it: { translation: transIt },
	en: { translation: transEn },
};

i18next
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		compatibilityJSON: "v3",
		resources,
		fallbackLng: "en",
		interpolation: {
			escapeValue: false,
		},
		react: {
			useSuspense: false,
		},
	});

export default i18next;
