import { Box, Modal } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	AttributeType,
	IKeyCloakGroup,
	useKeycloak,
} from "react-keycloak-manager";
import { ConfirmDialog } from "react-vevy-library";
import RangesTable from "../components/RangesTable";
import { VppCalRange } from "../interface/Constants";
import { ICalendarRange } from "../interface/Entities";
import { OperationType, RangeType } from "../interface/Enums";
import CalendarRangeEdit from "./CalendarRangeEdit";

interface IUserCalendarsProps {
	group: IKeyCloakGroup;
	onChange: (group: IKeyCloakGroup) => void;
}

export default function UserCalendars({
	group,
	onChange,
}: IUserCalendarsProps) {
	const { t } = useTranslation();
	const [ranges, setRanges] = useState<ICalendarRange[]>([]);
	const [currentRange, setCurrentRange] = useState<ICalendarRange>(
		{} as ICalendarRange,
	);
	const [showRange, setShowRange] = useState<boolean>(false);
	const [refresh, setRefresh] = useState<number>(0);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [maxId, setMaxId] = useState(0);

	useEffect(() => {
		if (group) {
			if (group?.attributes && group?.attributes[VppCalRange]) {
				var range = group?.attributes[VppCalRange] as string[];
				if (range) {
					var ranges: ICalendarRange[] = [];

					range.forEach((r) => {
						var calendarRange = JSON.parse(r) as ICalendarRange;
						ranges.push(calendarRange);
					});
					setRanges(ranges);
					var max =
						ranges.reduce<number>(
							(acc, r) => (r?.id > acc ? r?.id ?? 0 : acc),
							0,
						) ?? 0;
					setMaxId(max);
				}
			}
		}
	}, [group, refresh]);

	return (
		<Box
			sx={{
				flexDirection: "row",
				height: "75vh",
			}}
		>
			<Box>
				<RangesTable
					ranges={ranges}
					onEdit={(range: ICalendarRange) => {
						setCurrentRange(range);
						setShowRange(true);
					}}
					onAdd={() => {
						var range: ICalendarRange = {
							id: -1,
							calendarOperation: OperationType.Sum,
							enabled: false,
							rangeType: RangeType.Recursive,
							description: "",
							doorsOperation: OperationType.Sum,
							doors: [],
						};
						setCurrentRange(range);
						setShowRange(true);
					}}
					onDelete={async (range) => {
						setCurrentRange(range);
						setDialogOpen(true);
					}}
				/>
			</Box>
			<Box>
				<Modal
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
					onClose={(e, r) => {
						console.log(r);
						// if (r === "backdropClick") return;
						setShowRange(false);
					}}
					open={showRange}
				>
					<Fragment>
						{/* <RangeDetail
							range={currentRange}
							onCancel={() => {
								setShowRange(false);
							}}
							onConfirm={async (range) => {
								setShowRange(false);
								setCurrentRange(range);
								saveCalendarRange(range);
								setRefresh(Date.now);
								onChange(group);
							}}
						/> */}
						<CalendarRangeEdit
							range={currentRange}
							onCancel={() => {
								setShowRange(false);
							}}
							onConfirm={async (range) => {
								setShowRange(false);
								setCurrentRange(range);
								saveCalendarRange(range);
								setRefresh(Date.now);
								onChange(group);
							}}
						/>
					</Fragment>
				</Modal>
			</Box>
			<ConfirmDialog
				title={`${t("deleteRange")}`}
				content={`${t("deleteRangeMessage")} ${currentRange?.description}`}
				open={dialogOpen}
				actionOk={async () => {
					setDialogOpen(false);
					if (currentRange) {
						deleteCalendarRange(currentRange);
						setRefresh(Date.now);
						onChange(group);
					}
				}}
				actionCancel={() => {
					setDialogOpen(false);
				}}
			/>
		</Box>
	);

	function saveCalendarRange(c: ICalendarRange): void {
		c = cleanRange(c);
		if (c.id == -1) {
			c.id = maxId + 1;
			setMaxId(maxId + 1);
			ranges?.push(c);
		} else {
			var index = ranges?.findIndex((r) => r.id == c.id);
			if (index !== undefined && index >= 0 && ranges) ranges[index] = c;
		}
		updateAttributes(ranges);
	}

	function updateAttributes(ranges: ICalendarRange[]) {
		if (!group.attributes) group.attributes = {} as AttributeType;
		var toSave = ranges.map((r) => JSON.stringify(r));
		group.attributes[VppCalRange] = toSave;
	}

	function cleanRange(range: ICalendarRange): ICalendarRange {
		switch (range.rangeType) {
			case RangeType.Recursive:
				range.dateRange = undefined;
				break;
			case RangeType.Single:
				range.daysOfWeek = "";
				range.startEndDateRange = undefined;
				break;
		}
		return range;
	}

	function deleteCalendarRange(range: ICalendarRange): void {
		var index = ranges.findIndex((f) => f.id == range?.id);
		if (index !== null && index >= 0) ranges.splice(index, 1);

		updateAttributes(ranges);
	}
}
