import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	styled,
	tableCellClasses,
	useTheme,
} from "@mui/material";
import { IKeyCloakGroup } from "react-keycloak-manager";

interface GroupssTableProps {
	groups: IKeyCloakGroup[];
	onRefreshTable: () => void;
	onDeleteGroup: (calendar: IKeyCloakGroup) => void;
}

export default function GroupsTable({
	groups,
	onRefreshTable,
	onDeleteGroup,
}: GroupssTableProps) {
	const theme = useTheme();
	const { t } = useTranslation();

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.common.white,
			fontSize: 16,
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 16,
		},
	}));

	return (
		<TableContainer
			sx={{
				minHeight: "80vh",
				maxHeight: "80vh",
				overflowY: "false",
				marginTop: 1,
			}}
		>
			<Table stickyHeader aria-label='sticky table' size='small'>
				<TableHead>
					<TableRow>
						<StyledTableCell
							style={{
								background: theme.palette.primary.dark,
								color: theme.palette.common.white,
							}}
						>
							{t("groups")}
						</StyledTableCell>
						<StyledTableCell>{""}</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{groups.map((row) => {
						return (
							<TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
								<TableCell>{row.name}</TableCell>
								<TableCell size='small'>
									<Tooltip title={t("edit")}>
										<IconButton
											size='small'
											color='primary'
											component={Link}
											to={`/group?id=${row.id}`}
										>
											<EditIcon />
										</IconButton>
									</Tooltip>
									<Tooltip title={t("delete")}>
										<IconButton
											size='small'
											color='primary'
											aria-label='delete'
											onClick={(e) => onDeleteGroup(row)}
										>
											<DeleteIcon />
										</IconButton>
									</Tooltip>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
