import DateRangeIcon from "@mui/icons-material/DateRange";
import SensorDoorIcon from "@mui/icons-material/SensorDoor";
import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RangeDetail from "../components/RangeDetail";
import { CheckTime } from "../helpers/CalendarFunctions";
import { ICalendarRange } from "../interface/Entities";
import { ITabPanel, VerticalTabber } from "../libraries/Tabber";
import RangeDetailDoors from "../components/RangeDetailDoors";

interface CalendarRangeDetailProps {
	range: ICalendarRange;
	onConfirm: (range: ICalendarRange) => void;
	onCancel: () => void;
}

export default function CalendarRangeEdit({
	range,
	onCancel,
	onConfirm,
}: CalendarRangeDetailProps) {
	const { t } = useTranslation();
	const [value, setValue] = useState(0);
	const [rangeEdit, setRangeEdit] = useState<ICalendarRange>(range);
	const [rangeIsOk, setRangeIsOk] = useState<boolean>(false);

	useEffect(() => {
		setRangeEdit(range);
	}, [range]);

	useEffect(() => {
		setRangeIsOk(CheckTime(rangeEdit));
	}, [rangeEdit]);

	const tabs: ITabPanel[] = [
		{
			title: t("range"),
			panel: (
				<RangeDetail range={rangeEdit} onChange={(r) => setRangeEdit(r)} />
			),
			icon: <DateRangeIcon />,
		},
		{
			title: t("doors"),
			panel: (
				<RangeDetailDoors range={rangeEdit} onChange={(r) => setRangeEdit(r)} />
			),
			icon: <SensorDoorIcon />,
		},
	];

	return (
		<Box
			sx={{
				bgcolor: "background.paper",
				minWidth: 800,
				minHeight: 600,
				display: "flex",
				flexDirection: "column",
				alignContent: "center",
			}}
		>
			<VerticalTabber panels={tabs} curPanel={value} setPanel={setValue} />
			<Box
				sx={{
					justifyContent: "right",
					display: "flex",
					columnGap: 1,
					padding: 2,
				}}
			>
				<Button
					variant='contained'
					disabled={!rangeIsOk}
					onClick={() => {
						if (onConfirm) onConfirm(rangeEdit!);
					}}
				>
					{t("save")}
				</Button>
				<Button variant='contained' color='warning' onClick={onCancel}>
					{t("cancel")}
				</Button>
			</Box>
		</Box>
	);
}
