import { useCallback, useEffect, useState } from "react";
import UserGates from "./UserGates";
import { IKeyCloakGroup, useKeycloak } from "react-keycloak-manager";
import { Link, useSearchParams } from "react-router-dom";
import { Box, Button, Container } from "@mui/material";
import TextEditSave from "../components/TextEditSave";
import { useTranslation } from "react-i18next";
import { VppZoneName } from "../interface/Constants";

export default function Group() {
	const [searchParams] = useSearchParams();
	const { getGroup, setGroup } = useKeycloak();
	const [saveDisabled, setSaveDisabled] = useState(true);
	const { t } = useTranslation();

	const id = searchParams.get("id");

	const fetchGroup = useCallback(async () => {
		if (id) {
			var group = await getGroup(id);
			if (group) {
				if (group && group.attributes) {
					if (!group.attributes[VppZoneName])
						group.attributes[VppZoneName] = [];
					setCurrentGroup(group);
				}
			}
		}
	}, [id]);

	useEffect(() => {
		fetchGroup();
	}, [fetchGroup, id]);

	const [currentGroup, setCurrentGroup] = useState<IKeyCloakGroup>({});

	function onSaveGroupDesc(txt: string): void {
		var group: IKeyCloakGroup = { ...currentGroup } as IKeyCloakGroup;
		group.name = txt;
		setCurrentGroup(group);
		setSaveDisabled(false);
	}

	async function onSave() {
		await setGroup(currentGroup);
	}

	return (
		<Container
			maxWidth={false}
			sx={{
				flexDirection: "row",
				height: "90vh",
			}}
		>
			<TextEditSave text={currentGroup?.name} onConfirm={onSaveGroupDesc} />
			<Box>
				<UserGates
					group={currentGroup}
					onChange={() => {
						setSaveDisabled(false);
					}}
				/>
				;
			</Box>
			<Box sx={{ justifyContent: "right", display: "flex", columnGap: 1 }}>
				<Button
					variant='contained'
					disabled={saveDisabled}
					onClick={() => onSave()}
					component={Link}
					to='/groups'
				>
					{t("save")}
				</Button>
				<Button
					color='warning'
					variant='contained'
					component={Link}
					to='/groups'
				>
					{t("cancel")}
				</Button>
			</Box>
		</Container>
	);
}
