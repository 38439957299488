import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
	Collapse,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	styled,
	tableCellClasses,
	useTheme,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IKeyCloakUser, useKeycloak } from "react-keycloak-manager";
import { Link } from "react-router-dom";
import { VppCalendar } from "../interface/Constants";
import { ICalendar } from "../interface/Entities";

interface CalendarsTableProps {
	calendars: ICalendar[];
	onRefreshTable: () => void;
	onDeleteCalendar: (calendar: ICalendar) => void;
}

export default function CalendarsTable({
	calendars,
	onRefreshTable,
	onDeleteCalendar,
}: CalendarsTableProps) {
	const theme = useTheme();
	const { t } = useTranslation();
	const { getMembersOfGroup, findGroupsByAttribute } = useKeycloak();

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.common.white,
			fontSize: 16,
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 16,
		},
	}));

	async function readUserUsingCalendar(id: number): Promise<IKeyCloakUser[]> {
		var listUsers: IKeyCloakUser[] = [];
		var groups = await findGroupsByAttribute(VppCalendar, `${id}`);
		for (var i = 0; i < groups.length; i++) {
			var users = await getMembersOfGroup(groups[i].id!);
			listUsers.push(...users);
		}
		return listUsers;
	}

	const UserRow = ({ user }: { user: IKeyCloakUser }) => {
		return (
			<TableRow key={`caldet${user.id}`}>
				<StyledTableCell style={{ minWidth: 100, maxWidth: 200 }}>
					{`${user.firstName} ${user.lastName}`}
				</StyledTableCell>
				<StyledTableCell style={{ minWidth: 100, maxWidth: 200 }}>
					{user.email}
				</StyledTableCell>
			</TableRow>
		);
	};

	const CalendarRow = ({ row }: { row: ICalendar }) => {
		const [open, setOpen] = useState(false);
		const [userList, setUserList] = useState<IKeyCloakUser[]>();

		useEffect(() => {
			if (open && userList === undefined)
				readUserUsingCalendar(row.id).then((user) => setUserList(user));
		}, [open]);

		return (
			<Fragment>
				<TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
					<TableCell style={{ width: 10 }}>
						<IconButton
							aria-label='expand row'
							size='small'
							onClick={() => setOpen(!open)}
						>
							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					</TableCell>
					<TableCell>{row.description}</TableCell>
					<TableCell>
						<Tooltip title={t("edit")}>
							<IconButton
								color='primary'
								component={Link}
								to={`/calendar?id=${row.id}`}
							>
								<EditIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title={t("delete")}>
							<IconButton
								color='primary'
								aria-label='delete'
								onClick={(e) => onDeleteCalendar(row)}
							>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					</TableCell>
				</TableRow>
				<TableRow key={`det${row.id}`}>
					<TableCell
						style={{
							paddingBottom: 0,
							paddingTop: 10,
							borderBottom: "none",
						}}
						colSpan={3}
					>
						<Collapse in={open} timeout='auto' unmountOnExit>
							<Table
								size='small'
								aria-label='purchases'
								key={row.id}
								stickyHeader
							>
								<TableHead>
									<TableRow>
										<StyledTableCell>{t("username")}</StyledTableCell>
										<StyledTableCell>{t("email")}</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{userList?.map((user) => (
										<Fragment key={`fragdet${user.id}`}>
											<UserRow user={user} />
										</Fragment>
									))}
								</TableBody>
							</Table>
						</Collapse>
					</TableCell>
				</TableRow>
			</Fragment>
		);
	};

	return (
		<TableContainer
			sx={{
				minHeight: "80vh",
				maxHeight: "80vh",
				overflowY: "false",
				marginTop: 1,
			}}
		>
			<Table stickyHeader aria-label='sticky table' size='small'>
				<TableHead>
					<TableRow>
						<StyledTableCell> </StyledTableCell>
						<StyledTableCell
							style={{
								background: theme.palette.primary.dark,
								color: theme.palette.common.white,
							}}
						>
							{t("calendar")}
						</StyledTableCell>
						<StyledTableCell> </StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{calendars.map((row) => {
						return (
							<Fragment key={row.id}>
								<CalendarRow row={row} />
							</Fragment>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
